import { StyleFunctionProps } from '@chakra-ui/theme-tools'
import { Tabs } from '@chakra-ui/theme/components'

const tabStyles = {
  baseStyle: {
    tab: {
      // This is for the "ellipsis" textStyle to work correctly inside a tab.
      minWidth: '0',

      _selected: {
        backgroundColor: 'white.50',
      },
    },
    tablist: {
      position: 'relative',
      zIndex: '1',
    },
  },
  sizes: {
    // TODO[SFP1-338]: "sm" hasn't been properly defined in the theme, so these values are just a placeholder.
    sm: {
      tab: {
        fontSize: 'sm',
        height: '1.75rem',
        paddingX: '2',
        paddingY: '1',
      },
    },
    md: {
      tab: {
        fontSize: 'md',
        height: '2rem',
        paddingX: '4',
        paddingY: '1',
      },
    },
    // TODO[SFP1-338]: "lg" hasn't been properly defined in the theme, so these values are just a placeholder.
    lg: {
      tab: {
        fontSize: 'lg',
        height: '2.25rem',
        paddingX: '4',
        paddingY: '2',
      },
    },
  },
  variants: {
    enclosed: (props: StyleFunctionProps) => {
      const style = Tabs.variants?.enclosed(props)
      const { colorScheme } = props

      return {
        ...style,

        tab: {
          ...style?.tab,

          color: `${colorScheme}.600`,
          filter: 'saturate(0)',

          _selected: {
            ...style?.tab._selected,

            color: `${colorScheme}.900`,
            filter: 'saturate(1)',
            borderColor: `${colorScheme}.300`,
            borderBottomColor: 'white.50',
          },
        },
      }
    },
  },
}

export default tabStyles
