import { default as chakraTheme } from '@chakra-ui/theme'

// workaround for MACKAGE site theme
// TODO update once we have CMS controlled theme
const _primaryColor = process.env.NEXT_PUBLIC_THEME_COLOR_PRIMARY ?? undefined

export const palette = {
  // https://chakra-ui.com/docs/styled-system/theme#colors
  ...chakraTheme.colors,

  // #region Orium theme
  primary: {
    '100': '#FAE3F2',
    '200': '#F5C8E9',
    '300': '#E3A5D7',
    '400': '#E3A5D7',
    '500': '#A459A3',
    '600': '#267D8C',
    '700': '#6A2C76',
    '800': '#4E1C5F',
    '900': '#3A114E',
  },
  secondary: {
    '100': '#F2F3FC',
    '200': '#E5E7FA',
    '300': '#D3D4F0',
    '400': '#BFC1E2',
    '500': '#A5A7CF',
    '600': '#787BB2',
    '700': '#535595',
    '800': '#343678',
    '900': '#1F2163',
  },
  tertiary: {
    '100': '#FDFBFA',
    '200': '#FBF7F6',
    '300': '#F3EDED',
    '400': '#E8E0E0',
    '500': '#DAD0D1',
    '600': '#BB989E',
    '700': '#9C6877',
    '800': '#7E4258',
    '900': '#682746',
  },
  success: {
    '100': '#D2FBD3',
    '200': '#A7F8B0',
    '300': '#78EB90',
    '400': '#54D87C',
    '500': '#24BF62',
    '600': '#1AA45F',
    '700': '#128959',
    '800': '#0B6E50',
    '900': '#065B4A',
  },
  danger: {
    '100': '#FFE4D9',
    '200': '#FFC3B4',
    '300': '#FF9B8E',
    '400': '#FF7572',
    '500': '#FF4451',
    '600': '#DB314C',
    '700': '#B72247',
    '800': '#931540',
    '900': '#7A0D3C',
  },
  warning: {
    '100': '#FDEDD1',
    '200': '#FBD6A5',
    '300': '#F4B777',
    '400': '#EA9854',
    '500': '#DD6B20',
    '600': '#BE4F17',
    '700': '#9F3710',
    '800': '#80240A',
    '900': '#6A1606',
  },
  info: {
    '100': '#DCF4FE',
    '200': '#B9E6FE',
    '300': '#96D4FE',
    '400': '#7BC2FD',
    '500': '#50A6FC',
    '600': '#3A81D8',
    '700': '#2860B5',
    '800': '#194392',
    '900': '#0F2F78',
  },
  shading: {
    '100': '#F4F4F4',
    '200': '#EAEAEA',
    '300': '#C0C0C0',
    '400': '#828282',
    '500': '#404040',
    '600': '#303030',
    '700': '#222222',
    '800': '#1B1B1B',
    '900': '#111111',
  },
  // #endregion

  // #region Spoonflower theme
  gray: {
    50: '#F1F7F8',
    100: '#EBF1F2',
    200: '#D2DDDF',
    300: '#BAC8CC',
    400: '#AAB5B8',
    500: '#61696B',
    600: '#424A4B',
    700: '#273133',
    800: '#273133',
    900: '#1B2527',
  },
  /**
   * You'll notice that this is this exactly the same as "gray".
   * This is because we want to use the "gray" color scheme in buttons
   * without Chakra's alternate styling for it.
   *
   * Why "granite"? It's the color name for the "500" shade.
   * https://www.color-name.com/hex/61696b
   */
  granite: {
    50: '#F1F7F8',
    100: '#EBF1F2',
    200: '#D2DDDF',
    300: '#BAC8CC',
    400: '#AAB5B8',
    500: '#61696B',
    600: '#424A4B',
    700: '#273133',
    800: '#273133',
    900: '#1B2527',
  },
  teal: {
    50: '#F4FEFC',
    100: '#EAFCF9',
    200: '#D4F9F3',
    300: '#ACF3ED',
    400: '#7CDCDB',
    500: '#267D8C',
    600: '#1B6378',
    700: '#134C64',
    800: '#0C3651',
    900: '#00394B',
  },
  gold: {
    50: '#FFFBEA',
    100: '#FEF4CB',
    200: '#FEE598',
    300: '#FDD265',
    400: '#F9A200',
    500: '#D68300',
    600: '#CA6800',
    700: '#B36700',
    800: '#904E00',
    900: '#773C00',
  },
  blue: {
    50: '#F5FCFF',
    100: '#EAF9FE',
    200: '#CAF0FD',
    300: '#38AAE1',
    400: '#007FCE',
    500: '#0062B1',
    600: '#004994',
    700: '#00407D',
    800: '#003477',
    900: '#002562',
  },
  green: {
    50: '#F4FDF0',
    100: '#E2FAD8',
    200: '#C0F6B3',
    300: '#91E688',
    400: '#28943B',
    500: '#1B7C35',
    600: '#00641D',
    700: '#11642F',
    800: '#0A532A',
    900: '#0D4B31',
  },
  red: {
    50: '#FFF7F4',
    100: '#FDE0D4',
    200: '#FCBBAA',
    300: '#F68C7E',
    400: '#ED605D',
    500: '#C21F38',
    600: '#B3112C',
    700: '#A21538',
    800: '#830D35',
    900: '#6C0832',
  },
  fuchsia: {
    50: '#F9F2F7',
    100: '#F3E4EF',
    200: '#E1BCD8',
    300: '#CD90BE',
    400: '#B866A5',
    500: '#982B82',
    600: '#8C267D',
    700: '#7C1F75',
    800: '#6D1B6C',
    900: '#53145C',
  },
  // TODO: We need a "black" color scheme
  black: {
    50: '#0F1415',
    100: '#0F1415',
    200: '#0F1415',
    300: '#0F1415',
    400: '#0F1415',
    500: '#0F1415',
    600: '#0F1415',
    700: '#0F1415',
    800: '#0F1415',
    900: '#0F1415',
  },
  // TODO: We need a "white" color scheme
  white: {
    50: '#FEFEFE',
    100: '#FEFEFE',
    200: '#FEFEFE',
    300: '#FEFEFE',
    400: '#FEFEFE',
    500: '#FEFEFE',
    600: '#FEFEFE',
    700: '#FEFEFE',
    800: '#FEFEFE',
    900: '#FEFEFE',
  },
  'gray-opacity': {
    50: 'rgba(0, 0, 0, 0.04)',
    100: 'rgba(0, 0, 0, 0.06)',
    200: 'rgba(0, 0, 0, 0.08)',
    300: 'rgba(0, 0, 0, 0.16)',
    400: 'rgba(0, 0, 0, 0.24)',
    500: 'rgba(0, 0, 0, 0.36)',
    600: 'rgba(0, 0, 0, 0.48)',
    700: 'rgba(0, 0, 0, 0.64)',
    800: 'rgba(0, 0, 0, 0.80)',
    900: 'rgba(0, 0, 0, 0.92)',
  },
  'white-opacity': {
    50: 'rgba(255, 255, 255, 0.04)',
    100: 'rgba(255, 255, 255, 0.06)',
    200: 'rgba(255, 255, 255, 0.08)',
    300: 'rgba(255, 255, 255, 0.16)',
    400: 'rgba(255, 255, 255, 0.24)',
    500: 'rgba(255, 255, 255, 0.36)',
    600: 'rgba(255, 255, 255, 0.48)',
    700: 'rgba(255, 255, 255, 0.64)',
    800: 'rgba(255, 255, 255, 0.80)',
    900: 'rgba(255, 255, 255, 0.92)',
  },
  // #endregion
}

// Color Tokens - Global Status
const colorTokens = {
  // #region Orium theme
  // info
  'info-light': palette.info['100'],
  'info-med': palette.info['500'],
  'info-dark': palette.info['900'],
  // success
  'success-light': palette.success['100'],
  'success-med': palette.success['500'],
  'success-dark': palette.success['900'],
  // warning
  'warning-light': palette.warning['100'],
  'warning-med': palette.warning['500'],
  'warning-dark': palette.warning['700'],
  // danger
  'danger-light': palette.danger['100'],
  'danger-med': palette.danger['500'],
  'danger-dark': palette.danger['900'],
  // #endregion
}

export const paletteTokens = {
  colors: {
    // #region Orium theme
    light: {
      background: palette.white,
      text: palette.black, //Body foreground color
      'text-muted': palette.shading['400'], // Body foreground color (muted) for alternative styling
      // TODO update once we have CMS controlled theme
      primary: _primaryColor ?? palette.primary['500'], // Primary color for links, buttons, etc.
      secondary: palette.secondary['700'], //A secondary brand color for alternative styling
      highlight: palette.secondary['500'], //A highlight color for emphasizing UI
      muted: palette.tertiary['500'],
      accent: palette.tertiary['700'], //A contrast color for emphasizing UI
    },
    dark: {
      background: palette.black,
      text: palette.shading['100'], //Body foreground color
      'text-muted': palette.shading['300'], // Body foreground color (muted) for alternative styling
      // TODO update once we have CMS controlled theme
      primary: _primaryColor ?? palette.primary['600'], // Primary color for links, buttons, etc
      secondary: palette.primary['400'], //A secondary brand color for alternative styling
      highlight: palette.secondary['500'], //A highlight color for emphasizing UI
      muted: palette.tertiary['500'],
      accent: palette.tertiary['700'], //A contrast color for emphasizing UI
    },
    // #endregion

    // #region Spoonflower theme
    link: {
      teal: {
        base: palette.teal[500],
        hover: palette.teal[700],
      },
    },
    text: {
      disabled: palette.gray[400],
      primary: {
        base: palette.gray[900],
        hover: palette.black,
      },
      secondary: {
        base: palette.gray[700],
        hover: palette.gray[900],
      },
      tertiary: {
        base: palette.gray[500],
        hover: palette.gray[700],
      },
    },
    // #endregion
  },
}
export type Colors = typeof palette & typeof paletteTokens & typeof colorTokens

const colors = { ...palette, ...paletteTokens, ...colorTokens }

export default colors
