import { Flex, Box, useBreakpointValue } from '@chakra-ui/react'
import { BackToShoppingButton } from '../components/BackToShoppingButton'
import { SpoonFlowerLogo } from '../components/SpoonFlowerLogo'
import { useRouter } from 'next/router'
import { PATHS } from 'constants/paths'
import { useIntl } from 'react-intl'
import { useIsBrowserRedirect } from '../hooks/useIsBrowserRedirect'
import { SPOONFLOWER_BASE_URL } from '../../../constants'

interface HeaderLayoutProps {
  children: React.ReactNode
}

export const HeaderLayout = ({ children }: HeaderLayoutProps) => {
  const router = useRouter()
  const { pathname } = router
  const isButtonLarge = useBreakpointValue({ base: true, lg: false })
  const intl = useIntl()

  const isSuccessPage = pathname.includes('/checkout/success')
  const isReviewPage = router.asPath === PATHS.REVIEW
  const isCartPage = router.pathname === PATHS.CART

  // checks if the user is trying to access pages using the browser back button
  useIsBrowserRedirect()

  return (
    <Box
      paddingTop={{ base: 4, md: 7 }}
      as="header"
      backgroundColor="white.50"
      boxShadow="base"
      zIndex={1}
    >
      <Flex
        paddingRight={isCartPage ? 6 : 0}
        flexDirection={{ base: isCartPage ? 'row' : 'column', md: 'row' }}
        width="100%"
        maxWidth="container.3xl"
        marginInline="auto"
      >
        <SpoonFlowerLogo />
        {!isSuccessPage && (
          <Flex gap={4} paddingLeft={{ base: 4, sm: 6, md: 0 }}>
            {children}
          </Flex>
        )}

        {isCartPage && (
          <BackToShoppingButton
            redirectUrl={SPOONFLOWER_BASE_URL}
            buttonText={
              isButtonLarge
                ? intl.formatMessage({
                  id: 'checkout.button.backToShoppingShort',
                })
                : intl.formatMessage({ id: 'checkout.button.backToShopping' })
            }
          />
        )}
        {isReviewPage && (
          <BackToShoppingButton
            redirectUrl={PATHS.CART}
            buttonText={intl.formatMessage({ id: 'checkout.button.editCart' })}
          />
        )}
      </Flex>
    </Box>
  )
}
