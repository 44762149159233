import {
  PAYMENT_METHOD_AFTERPAY,
  PAYMENT_METHOD_BRAINTREE,
  PAYMENT_METHOD_PAYPAL,
  PAYMENT_METHOD_SPOONDOLLARS,
} from '@modules/checkout'

export const SPOONFLOWER_BASE_URL =
  process.env.NEXT_PUBLIC_SPOONFLOWER_BASE_URL ?? 'https://www.spoonflower.com'

export const COMMERCETOOLS_CLIENT_ID =
  process.env.NEXT_PUBLIC_COMMERCETOOLS_CLIENT_ID ?? ''

export const COMMERCETOOLS_PROJECT_KEY =
  process.env.NEXT_PUBLIC_COMMERCETOOLS_PROJECT_KEY ?? ''

export const COMMERCETOOLS_CLIENT_SECRET =
  process.env.NEXT_PUBLIC_COMMERCETOOLS_CLIENT_SECRET ?? ''

export const COMMERCETOOLS_HOST =
  process.env.NEXT_PUBLIC_COMMERCETOOLS_HOST ?? ''

export const algoliaConfig = {
  appId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID || '',
  searchApiKey: process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY || '',
  defaultIndex: `${process.env.NEXT_PUBLIC_ALGOLIA_BASE_INDEX}_${process.env.NEXT_PUBLIC_BRAND_NAME}_${process.env.NEXT_PUBLIC_DEFAULT_LOCALE}`,
}

export const IMAGE_PLACEHOLDER = '/img/image-placeholder.svg'

export const GOOGLE_TAG_MANAGER_ID =
  process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID

export const GOOGLE_ANALYTICS_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID

/* Brand name determines what contentful content will be pulled
for ex:
- single site can set BRAND_NAME to '',
- multi-site: set BRAND_A, BRAND_B etc
*/
export const BRAND_NAME = process.env.NEXT_PUBLIC_BRAND_NAME ?? ''

export const DEFAULT_LOCALE = process.env.NEXT_PUBLIC_DEFAULT_LOCALE

//revalidate PDP product/[slug] route every 30 sec
export const PDP_SSG_REVALIDATE_TIME_IN_SEC = process.env
  .NEXT_PUBLIC_PDP_SSG_REVALIDATE_TIME_IN_SEC
  ? Number(process.env.NEXT_PUBLIC_PDP_SSG_REVALIDATE_TIME_IN_SEC)
  : 30

export const MEGA_MENU_ID = {
  MAIN_NAV: `${BRAND_NAME ? BRAND_NAME + '/' : ''}mega-menu-nav`,
  FOOTER: `${BRAND_NAME ? BRAND_NAME + '/' : ''}mega-menu-footer`,
} as const

export const PRODUCT_CHANNEL =
  process.env.NEXT_PUBLIC_COMMERCETOOLS_INVENTORY_CHANNEL ??
  (BRAND_NAME ? `${BRAND_NAME}-channel` : undefined)

export const countries = [
  {
    name: 'Canada',
    code: 'CA',
    intlId: 'text.canada',
  },
  {
    name: 'United States',
    code: 'US',
    intlId: 'text.unitedStates',
  },
  {
    name: 'Australia',
    code: 'AU',
    intlId: 'text.australia',
  },
]

export const ARTIST_NAME_LABEL = 'Artist Name'
export const DESIGN_DESCRIPTION_LABEL = 'Design Description'
export const SIZE_LABEL = 'Size'
export const USER_PREFERENCES_KEY = 'SP_userPreferences'

export const BRAINTREE_MERCHANT_ID = process.env.BRAINTREE_MERCHANT_ID ?? ''
export const BRAINTREE_PUBLIC_KEY = process.env.BRAINTREE_PUBLIC_KEY ?? ''
export const BRAINTREE_PRIVATE_KEY = process.env.BRAINTREE_PRIVATE_KEY ?? ''

export const BRAINTREE_MERCHANT_ID_USD =
  process.env.BRAINTREE_MERCHANT_ID_USD ?? ''
export const BRAINTREE_MERCHANT_ID_AUD =
  process.env.BRAINTREE_MERCHANT_ID_AUD ?? ''
export const BRAINTREE_MERCHANT_ID_CAD =
  process.env.BRAINTREE_MERCHANT_ID_CAD ?? ''
export const BRAINTREE_MERCHANT_ID_GBP =
  process.env.BRAINTREE_MERCHANT_ID_GBP ?? ''
export const BRAINTREE_MERCHANT_ID_EUR =
  process.env.BRAINTREE_MERCHANT_ID_EUR ?? ''

export const NEXT_PUBLIC_AFTERPAY_REDIRECT =
  process.env.NEXT_PUBLIC_AFTERPAY_REDIRECT ?? ''
export const NEXT_PUBLIC_AFTERPAY_CANCEL =
  process.env.NEXT_PUBLIC_AFTERPAY_CANCEL ?? ''
export const NEXT_PUBLIC_AFTERPAY_ORIGIN =
  process.env.NEXT_PUBLIC_AFTERPAY_ORIGIN ?? ''

const AFTERPAY_TOKEN_USA = process.env.AFTERPAY_TOKEN_USA ?? ''
const AFTERPAY_MID_USA = process.env.AFTERPAY_MID_USA ?? ''
const AFTERPAY_TOKEN_AU = process.env.AFTERPAY_TOKEN_AU ?? ''
const AFTERPAY_MID_AU = process.env.AFTERPAY_MID_AU ?? ''

interface SupportedCountries {
  [key: string]: {
    credentialsBuffer: Buffer
  }
}

export const AFTERPAY_API_URL =
  process.env.AFTERPAY_API_URL ?? 'https://global-api-sandbox.afterpay.com/v2'
export const NEXT_PUBLIC_AFTERPAY_SCRIPT_URL =
  process.env.NEXT_PUBLIC_AFTERPAY_SCRIPT_URL ??
  'https://portal.sandbox.afterpay.com/afterpay.js'

export const AFTERPAY_SUPPORTED_COUNTRIES: SupportedCountries = {
  US: {
    credentialsBuffer: Buffer.from(`${AFTERPAY_MID_USA}:${AFTERPAY_TOKEN_USA}`),
  },
  AU: {
    credentialsBuffer: Buffer.from(`${AFTERPAY_MID_AU}:${AFTERPAY_TOKEN_AU}`),
  },
}

export const AFTERPAY_TURNAROUND_MAX_DAYS = 28

export const NEXT_PUBLIC_ID_ME_CLIENT_ID =
  process.env.NEXT_PUBLIC_ID_ME_CLIENT_ID ?? ''
export const NEXT_PUBLIC_ID_ME_CALLBACK_URL =
  process.env.NEXT_PUBLIC_ID_ME_CALLBACK_URL ?? ''
export const NEXT_PUBLIC_CUSTOM_TYPE_KEY =
  process.env.NEXT_PUBLIC_CUSTOM_TYPE_KEY ?? ''

export const ANONYMOUS_USER_COOKIE = process.env.SF_ANON_COOKIE_KEY ?? ''

export const TAX_SHIPPING_CODE = 'SHIP-001'

export const CART_PAYMENT_METHOD_TYPES = {
  BRAINTREE: PAYMENT_METHOD_BRAINTREE,
  PAYPAL: PAYMENT_METHOD_PAYPAL,
  SPOONDOLLARS: PAYMENT_METHOD_SPOONDOLLARS,
  AFTERPAY: PAYMENT_METHOD_AFTERPAY,
}

// Process response code documentation: https://developer.paypal.com/braintree/docs/reference/general/processor-responses/authorization-responses
export const BRAINTREE_SUCCESS_CODES = ['1000', '1001', '1002', '1003', '1004']

// Afterpay payment capture documentation: https://developers.afterpay.com/docs/api/reference/immediate-payment-flow/operations/create-a-v-2-payment-capture
export const AFTERPAY_PAYMENT_SUCCESS_CODE = 'APPROVED'

export const BRAINTREE_FRAUD_REJECTION_REASONS = ['fraud', 'risk_threshold']

export enum ProcessOrderError {
  SetOrderNumber = 'Failed to set order number',
  SpoondollarCTPayment = 'Failed to deduct Spoondollars (aka create CT payment)',
  CartReplication = 'Failed to replicate cart',
  PaymentCapture = 'Failed to capture payment',
  RequiredData = 'Failed to fetch Order and/or Spoondollars',
  PaymentCaptureFraud = 'Failed to capture payment FPA',
  LineItemsInvalid = 'Failed to validate line items',
}

// Analytics
export const PAGE_TYPE_CT_CART = 'CT_cart'
export const EVENT_USER_INFORMATION = 'user_information'

// Slack Web Client
// TODO: This needs to be replaced with a proper NODE_ENV check.
export const KLINGON = process.env.KLINGON ?? 'Gowron'

export const LEARN_MORE_ID_ME_LINK = 'https://spoonflower.com/en/id-me'

// New Relic
export const NEW_RELIC_ACCOUNT_ID = process.env.NEXT_PUBLIC_NEW_RELIC_ACCOUNT_ID
export const NEW_RELIC_APP_ID = process.env.NEXT_PUBLIC_NEW_RELIC_APP_ID
export const NEW_RELIC_BROWSER_LICENSE_KEY =
  process.env.NEXT_PUBLIC_NEW_RELIC_BROWSER_LICENSE_KEY
