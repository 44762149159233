import { StyleFunctionProps } from '@chakra-ui/theme-tools'

const inputStyles = {
  baseStyle: {},
  sizes: {},
  variants: {
    outline: (props: StyleFunctionProps) => {
      return {
        field: {
          backgroundColor: 'white.50',
          color: 'gray.900',
          borderColor: 'gray.600',
          _hover: {
            borderColor: 'gray.700',
          },
          _focus: {
            borderWidth: '2px',
          },
          _invalid: {
            borderWidth: '2px',
            boxShadow: 'none',
          },
          _placeholder: {
            color: '#737D80',
          },
          _readOnly: {
            _focus: {
              boxShadow: 'none',
              borderColor: 'gray.600',
              borderWidth: '1px',
            },
          },
        },

        addon: {
          color: 'teal.500',
        },
      }
    },
  },
  defaultProps: {
    errorBorderColor: 'red.500',
    focusBorderColor: 'teal.600',
  },
}

export default inputStyles
