/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** Represents a currency. Currencies are identified by their [ISO 4217](http://www.iso.org/iso/home/standards/currency_codes.htm) currency codes. */
  Currency: any
  /** DateTime is a scalar value that represents an ISO8601 formatted date. */
  Date: any
  /** DateTime is a scalar value that represents an ISO8601 formatted date and time. */
  DateTime: any
  JSON: any
  /** Raw JSON value */
  Json: any
  /** Locale is a scalar value represented as a string language tag. */
  Locale: any
  /** The `Long` scalar type represents non-fractional signed whole numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: any
  /** A set. */
  Set: any
}

export type AwsLambdaDestination = ExtensionDestination & {
  arn: Scalars['String']
  accessKey: Scalars['String']
  accessSecret: Scalars['String']
  type: Scalars['String']
}

export type AbsoluteDiscountValue = CartDiscountValue &
  ProductDiscountValue & {
    money: Array<Money>
    type: Scalars['String']
  }

export enum ActionType {
  Update = 'Update',
  Create = 'Create',
}

export type AddStagedOrderCustomLineItemOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    draft: CustomLineItemDraftOutput
  }

export type AddStagedOrderDeliveryOutput = StagedOrderUpdateActionOutput & {
  type: Scalars['String']
  items: Array<DeliveryItem>
  parcels: Array<ParcelData>
  address?: Maybe<AddressDraft>
  custom?: Maybe<CustomFieldsCommand>
  shippingKey?: Maybe<Scalars['String']>
}

export type AddStagedOrderDiscountCodeOutput = StagedOrderUpdateActionOutput & {
  type: Scalars['String']
  code: Scalars['String']
  validateDuplicates: Scalars['Boolean']
}

export type AddStagedOrderItemShippingAddressOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    address: AddressDraft
  }

export type AddStagedOrderLineItemOutput = StagedOrderUpdateActionOutput & {
  type: Scalars['String']
  draft: LineItemDraftOutput
}

export type AddStagedOrderParcelToDeliveryOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    deliveryId: Scalars['String']
    measurements?: Maybe<ParcelMeasurements>
    trackingData?: Maybe<TrackingData>
    items: Array<DeliveryItem>
    custom?: Maybe<CustomFieldsCommand>
  }

export type AddStagedOrderPaymentOutput = StagedOrderUpdateActionOutput & {
  type: Scalars['String']
  paymentResId: ResourceIdentifier
}

export type AddStagedOrderReturnInfoOutput = StagedOrderUpdateActionOutput & {
  type: Scalars['String']
  items: Array<ReturnItemDraftTypeOutput>
  returnDate?: Maybe<Scalars['DateTime']>
  returnTrackingId?: Maybe<Scalars['String']>
}

export type AddStagedOrderShoppingListOutput = StagedOrderUpdateActionOutput & {
  type: Scalars['String']
  shoppingListResId: ResourceIdentifier
  supplyChannelResId?: Maybe<ChannelReferenceIdentifier>
  distributionChannelResId?: Maybe<ChannelReferenceIdentifier>
}

export type AddToCartItem = {
  fabric?: InputMaybe<Scalars['String']>
  fabric_size?: InputMaybe<Scalars['String']>
  design_id?: InputMaybe<Scalars['Int']>
  quantity: Scalars['Int']
  home_good_code?: InputMaybe<Scalars['String']>
  stock_product_code?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['String']>
  home_good_addition_codes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  saved?: InputMaybe<Scalars['Boolean']>
  chunk_size?: InputMaybe<Scalars['Int']>
  template_id?: InputMaybe<Scalars['Int']>
  dpi?: InputMaybe<Scalars['Int']>
  repeat_type?: InputMaybe<Scalars['String']>
}

export type AddToCartReturn = {
  id: Scalars['String']
  order_id: Scalars['String']
}

/** An address represents a postal address. */
export type Address = {
  id?: Maybe<Scalars['String']>
  streetName?: Maybe<Scalars['String']>
  streetNumber?: Maybe<Scalars['String']>
  additionalStreetInfo?: Maybe<Scalars['String']>
  postalCode?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  region?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  country: Country
  company?: Maybe<Scalars['String']>
  department?: Maybe<Scalars['String']>
  building?: Maybe<Scalars['String']>
  apartment?: Maybe<Scalars['String']>
  pOBox?: Maybe<Scalars['String']>
  additionalAddressInfo?: Maybe<Scalars['String']>
  externalId?: Maybe<Scalars['String']>
  key?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  mobile?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  fax?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  salutation?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  custom?: Maybe<CustomFieldsType>
}

export type AddressDraft = {
  id?: Maybe<Scalars['String']>
  streetName?: Maybe<Scalars['String']>
  streetNumber?: Maybe<Scalars['String']>
  additionalStreetInfo?: Maybe<Scalars['String']>
  postalCode?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  region?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  country: Country
  company?: Maybe<Scalars['String']>
  department?: Maybe<Scalars['String']>
  building?: Maybe<Scalars['String']>
  apartment?: Maybe<Scalars['String']>
  pOBox?: Maybe<Scalars['String']>
  additionalAddressInfo?: Maybe<Scalars['String']>
  externalId?: Maybe<Scalars['String']>
  key?: Maybe<Scalars['String']>
  custom?: Maybe<CustomFieldsCommand>
  phone?: Maybe<Scalars['String']>
  mobile?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  fax?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  salutation?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
}

export enum AdultContent {
  AdultContentOn = 'ADULT_CONTENT_ON',
  AdultContentOff = 'ADULT_CONTENT_OFF',
}

export enum AlpCurrency {
  Usd = 'USD',
  Cad = 'CAD',
  Eur = 'EUR',
  Aud = 'AUD',
  Gbp = 'GBP',
}

export type Analytics = {
  sku: Scalars['String']
  parent_sku: Scalars['String']
}

export type Applied = OrderEditResult & {
  appliedAt: Scalars['DateTime']
  excerptBeforeEdit: OrderExcerpt
  excerptAfterEdit: OrderExcerpt
  type: Scalars['String']
}

export type Asset = {
  id: Scalars['String']
  key?: Maybe<Scalars['String']>
  sources: Array<AssetSource>
  name?: Maybe<Scalars['String']>
  nameAllLocales: Array<LocalizedString>
  description?: Maybe<Scalars['String']>
  descriptionAllLocales?: Maybe<Array<LocalizedString>>
  tags: Array<Scalars['String']>
  custom?: Maybe<CustomFieldsType>
}

export type AssetNameArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type AssetDescriptionArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type AssetDimensions = {
  width: Scalars['Int']
  height: Scalars['Int']
}

export type AssetSource = {
  uri: Scalars['String']
  key?: Maybe<Scalars['String']>
  dimensions?: Maybe<AssetDimensions>
  contentType?: Maybe<Scalars['String']>
}

/** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
export type Associate = {
  roles: Array<AssociateRole>
  customerRef?: Maybe<Reference>
  customer?: Maybe<Customer>
}

/** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
export enum AssociateRole {
  Admin = 'Admin',
  Buyer = 'Buyer',
}

export enum AttributeConstraint {
  /** No constraints are applied to the attribute */
  None = 'None',
  /** Attribute value should be different in each variant */
  Unique = 'Unique',
  /** A set of attributes, that have this constraint, should have different combinations in each variant */
  CombinationUnique = 'CombinationUnique',
  /** Attribute value should be the same in all variants */
  SameForAll = 'SameForAll',
}

export type AttributeDefinition = {
  type: AttributeDefinitionType
  name: Scalars['String']
  label?: Maybe<Scalars['String']>
  isRequired: Scalars['Boolean']
  attributeConstraint: AttributeConstraint
  inputTip?: Maybe<Scalars['String']>
  inputHint: TextInputHint
  isSearchable: Scalars['Boolean']
  labelAllLocales: Array<LocalizedString>
  inputTipAllLocales?: Maybe<Array<LocalizedString>>
}

export type AttributeDefinitionLabelArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type AttributeDefinitionInputTipArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type AttributeDefinitionResult = {
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  total: Scalars['Int']
  results: Array<AttributeDefinition>
}

/** (https://docs.commercetools.com/api/projects/productTypes#attributetype)[https://docs.commercetools.com/api/projects/productTypes#attributetype] */
export type AttributeDefinitionType = {
  name: Scalars['String']
}

/** AuthenticationMode values. BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
export enum AuthenticationMode {
  ExternalAuth = 'ExternalAuth',
  Password = 'Password',
}

export type AuthorizationHeader = HttpDestinationAuthentication & {
  headerValue: Scalars['String']
  type: Scalars['String']
}

export type AzureFunctionsAuthentication = HttpDestinationAuthentication & {
  key: Scalars['String']
  type: Scalars['String']
}

export type BaseMoney = {
  type: Scalars['String']
  currencyCode: Scalars['Currency']
  centAmount: Scalars['Long']
  fractionDigits: Scalars['Int']
}

export type BooleanAttributeDefinitionType = AttributeDefinitionType & {
  name: Scalars['String']
}

export type BooleanType = FieldType & {
  name: Scalars['String']
}

/** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
export type BusinessUnit = Versioned & {
  key: Scalars['String']
  name: Scalars['String']
  contactEmail?: Maybe<Scalars['String']>
  addresses: Array<Address>
  defaultShippingAddress?: Maybe<Address>
  defaultBillingAddress?: Maybe<Address>
  defaultShippingAddressId?: Maybe<Scalars['String']>
  defaultBillingAddressId?: Maybe<Scalars['String']>
  shippingAddresses: Array<Address>
  billingAddresses: Array<Address>
  shippingAddressIds: Array<Scalars['String']>
  billingAddressIds: Array<Scalars['String']>
  status: BusinessUnitStatus
  storesRef?: Maybe<Array<KeyReference>>
  stores?: Maybe<Array<Store>>
  storeMode?: Maybe<Scalars['String']>
  unitType: BusinessUnitType
  associates: Array<Associate>
  custom?: Maybe<CustomFieldsType>
  parentUnitRef?: Maybe<KeyReference>
  parentUnit?: Maybe<BusinessUnit>
  /** This field contains the BusinessUnits KeyReferences from the Company to the parent Division of this BusinessUnit in that order. */
  ancestors: Array<BusinessUnit>
  topLevelUnitRef?: Maybe<KeyReference>
  topLevelUnit: BusinessUnit
  id: Scalars['String']
  version: Scalars['Long']
  createdAt: Scalars['DateTime']
  lastModifiedAt: Scalars['DateTime']
  createdBy?: Maybe<Initiator>
  lastModifiedBy?: Maybe<Initiator>
}

export type BusinessUnitAddressAdded = MessagePayload & {
  address: Address
  type: Scalars['String']
}

export type BusinessUnitAddressChanged = MessagePayload & {
  address: Address
  type: Scalars['String']
}

export type BusinessUnitAddressRemoved = MessagePayload & {
  address: Address
  type: Scalars['String']
}

export type BusinessUnitAssociateAdded = MessagePayload & {
  associate: Associate
  type: Scalars['String']
}

export type BusinessUnitAssociateChanged = MessagePayload & {
  associate: Associate
  type: Scalars['String']
}

export type BusinessUnitAssociateRemoved = MessagePayload & {
  associate: Associate
  type: Scalars['String']
}

export type BusinessUnitAssociatesSet = MessagePayload & {
  associates: Array<Associate>
  type: Scalars['String']
}

export type BusinessUnitBillingAddressAdded = MessagePayload & {
  address: Address
  type: Scalars['String']
}

export type BusinessUnitBillingAddressRemoved = MessagePayload & {
  address: Address
  type: Scalars['String']
}

export type BusinessUnitContactEmailSet = MessagePayload & {
  contactEmail?: Maybe<Scalars['String']>
  type: Scalars['String']
}

export type BusinessUnitCreated = MessagePayload & {
  businessUnit: BusinessUnit
  type: Scalars['String']
}

export type BusinessUnitDefaultBillingAddressSet = MessagePayload & {
  address?: Maybe<Address>
  type: Scalars['String']
}

export type BusinessUnitDefaultShippingAddressSet = MessagePayload & {
  address?: Maybe<Address>
  type: Scalars['String']
}

export type BusinessUnitDeleted = MessagePayload & {
  type: Scalars['String']
}

export type BusinessUnitNameChanged = MessagePayload & {
  name: Scalars['String']
  type: Scalars['String']
}

export type BusinessUnitParentChanged = MessagePayload & {
  oldParentUnit?: Maybe<KeyReference>
  newParentUnit?: Maybe<KeyReference>
  type: Scalars['String']
}

export type BusinessUnitShippingAddressAdded = MessagePayload & {
  address: Address
  type: Scalars['String']
}

export type BusinessUnitShippingAddressRemoved = MessagePayload & {
  address: Address
  type: Scalars['String']
}

/** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
export enum BusinessUnitStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export type BusinessUnitStatusChanged = MessagePayload & {
  status: BusinessUnitStatus
  type: Scalars['String']
}

export type BusinessUnitStoreAdded = MessagePayload & {
  store: KeyReference
  type: Scalars['String']
}

export type BusinessUnitStoreModeChanged = MessagePayload & {
  storeMode: Scalars['String']
  oldStoreMode: Scalars['String']
  stores?: Maybe<Array<KeyReference>>
  oldStores?: Maybe<Array<KeyReference>>
  type: Scalars['String']
}

export type BusinessUnitStoreRemoved = MessagePayload & {
  store: KeyReference
  type: Scalars['String']
}

export type BusinessUnitStoresSet = MessagePayload & {
  stores: Array<KeyReference>
  type: Scalars['String']
}

/** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
export enum BusinessUnitType {
  Company = 'Company',
  Division = 'Division',
}

export type CtUser = {
  id: Scalars['String']
  email?: Maybe<Scalars['String']>
  key?: Maybe<Scalars['String']>
}

/** A shopping cart holds product variants and can be ordered. Each cart either belongs to a registered customer or is an anonymous cart. */
export type Cart = Versioned &
  ReferenceExpandable & {
    customerId?: Maybe<Scalars['String']>
    customer?: Maybe<Customer>
    customerEmail?: Maybe<Scalars['String']>
    anonymousId?: Maybe<Scalars['String']>
    lineItems: Array<LineItem>
    customLineItems: Array<CustomLineItem>
    totalPrice: Money
    taxedPrice?: Maybe<TaxedPrice>
    shippingAddress?: Maybe<Address>
    billingAddress?: Maybe<Address>
    inventoryMode: InventoryMode
    taxMode: TaxMode
    taxRoundingMode: RoundingMode
    taxCalculationMode: TaxCalculationMode
    customerGroup?: Maybe<CustomerGroup>
    customerGroupRef?: Maybe<Reference>
    country?: Maybe<Country>
    shippingInfo?: Maybe<ShippingInfo>
    discountCodes: Array<DiscountCodeInfo>
    /** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
    directDiscounts: Array<DirectDiscount>
    refusedGifts: Array<CartDiscount>
    refusedGiftsRefs: Array<Reference>
    paymentInfo?: Maybe<PaymentInfo>
    locale?: Maybe<Scalars['Locale']>
    shippingRateInput?: Maybe<ShippingRateInput>
    origin: CartOrigin
    storeRef?: Maybe<KeyReference>
    store?: Maybe<Store>
    itemShippingAddresses: Array<Address>
    businessUnit?: Maybe<KeyReference>
    cartState: CartState
    key?: Maybe<Scalars['String']>
    custom?: Maybe<CustomFieldsType>
    deleteDaysAfterLastModification?: Maybe<Scalars['Int']>
    totalLineItemQuantity?: Maybe<Scalars['Long']>
    id: Scalars['String']
    version: Scalars['Long']
    createdAt: Scalars['DateTime']
    lastModifiedAt: Scalars['DateTime']
    createdBy?: Maybe<Initiator>
    lastModifiedBy?: Maybe<Initiator>
  }

/** A shopping cart holds product variants and can be ordered. Each cart either belongs to a registered customer or is an anonymous cart. */
export type CartLineItemsArgs = {
  id?: InputMaybe<Scalars['String']>
}

export type CartCount = {
  cartCount: Scalars['Int']
}

export type CartCreated = MessagePayload & {
  totalPrice: Money
  lineItemCount: Scalars['Int']
  discountCodesRefs: Array<Reference>
  type: Scalars['String']
}

/**
 * Cart discounts are recalculated every time LineItems or CustomLineItems are added or removed from the Cart or an order is created from the cart.
 * The number of active cart discounts that do not require a discount code (isActive=true and requiresDiscountCode=false) is limited to 100.
 */
export type CartDiscount = Versioned &
  ReferenceExpandable & {
    cartPredicate: Scalars['String']
    validFrom?: Maybe<Scalars['DateTime']>
    validUntil?: Maybe<Scalars['DateTime']>
    stackingMode: StackingMode
    isActive: Scalars['Boolean']
    requiresDiscountCode: Scalars['Boolean']
    sortOrder: Scalars['String']
    key?: Maybe<Scalars['String']>
    name?: Maybe<Scalars['String']>
    description?: Maybe<Scalars['String']>
    nameAllLocales: Array<LocalizedString>
    descriptionAllLocales?: Maybe<Array<LocalizedString>>
    referenceRefs: Array<Reference>
    custom?: Maybe<CustomFieldsType>
    value: CartDiscountValue
    target?: Maybe<CartDiscountTarget>
    id: Scalars['String']
    version: Scalars['Long']
    createdAt: Scalars['DateTime']
    lastModifiedAt: Scalars['DateTime']
    createdBy?: Maybe<Initiator>
    lastModifiedBy?: Maybe<Initiator>
  }

/**
 * Cart discounts are recalculated every time LineItems or CustomLineItems are added or removed from the Cart or an order is created from the cart.
 * The number of active cart discounts that do not require a discount code (isActive=true and requiresDiscountCode=false) is limited to 100.
 */
export type CartDiscountNameArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

/**
 * Cart discounts are recalculated every time LineItems or CustomLineItems are added or removed from the Cart or an order is created from the cart.
 * The number of active cart discounts that do not require a discount code (isActive=true and requiresDiscountCode=false) is limited to 100.
 */
export type CartDiscountDescriptionArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type CartDiscountTarget = {
  type: Scalars['String']
}

export type CartDiscountValue = {
  type: Scalars['String']
}

export enum CartOrigin {
  /** The cart was created by our platform and belongs to a Quote. BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
  Quote = 'Quote',
  /** The cart was created by the merchant on behalf of the customer */
  Merchant = 'Merchant',
  /** The cart was created by the customer. This is the default value */
  Customer = 'Customer',
}

export type CartPatchItem = {
  id: Scalars['Int']
  quantity?: InputMaybe<Scalars['Int']>
  saved?: InputMaybe<Scalars['Boolean']>
}

export enum CartState {
  Frozen = 'Frozen',
  /** The cart was ordered. No further operations on the cart are allowed. */
  Ordered = 'Ordered',
  /** Anonymous cart whose content was merged into a customers cart on signin. No further operations on the cart are allowed. */
  Merged = 'Merged',
  /** The cart can be updated and ordered. It is the default state. */
  Active = 'Active',
}

export enum CartStatus {
  Pending = 'pending',
  Saved = 'saved',
  Paid = 'paid',
  Processing = 'processing',
  Shipped = 'shipped',
  Hold = 'hold',
}

export type Category = Versioned &
  ReferenceExpandable & {
    id: Scalars['String']
    key?: Maybe<Scalars['String']>
    version: Scalars['Long']
    name?: Maybe<Scalars['String']>
    nameAllLocales: Array<LocalizedString>
    description?: Maybe<Scalars['String']>
    descriptionAllLocales?: Maybe<Array<LocalizedString>>
    slug?: Maybe<Scalars['String']>
    slugAllLocales: Array<LocalizedString>
    ancestorsRef: Array<Reference>
    ancestors: Array<Category>
    parentRef?: Maybe<Reference>
    parent?: Maybe<Category>
    orderHint: Scalars['String']
    externalId?: Maybe<Scalars['String']>
    metaTitle?: Maybe<Scalars['String']>
    metaTitleAllLocales?: Maybe<Array<LocalizedString>>
    metaKeywords?: Maybe<Scalars['String']>
    metaKeywordsAllLocales?: Maybe<Array<LocalizedString>>
    metaDescription?: Maybe<Scalars['String']>
    metaDescriptionAllLocales?: Maybe<Array<LocalizedString>>
    /** Number of staged products in the category subtree. */
    stagedProductCount: Scalars['Int']
    /** Number of direct child categories. */
    childCount: Scalars['Int']
    /** Direct child categories. */
    children?: Maybe<Array<Category>>
    createdAt: Scalars['DateTime']
    lastModifiedAt: Scalars['DateTime']
    assets: Array<Asset>
    custom?: Maybe<CustomFieldsType>
    createdBy?: Maybe<Initiator>
    lastModifiedBy?: Maybe<Initiator>
  }

export type CategoryNameArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type CategoryDescriptionArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type CategorySlugArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type CategoryMetaTitleArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type CategoryMetaKeywordsArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type CategoryMetaDescriptionArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type CategoryCreated = MessagePayload & {
  category: Category
  type: Scalars['String']
}

export type CategoryOrderHint = {
  categoryId: Scalars['String']
  orderHint: Scalars['String']
}

export type CategorySlugChanged = MessagePayload & {
  slug?: Maybe<Scalars['String']>
  oldSlug?: Maybe<Scalars['String']>
  slugAllLocales: Array<LocalizedString>
  oldSlugAllLocales?: Maybe<Array<LocalizedString>>
  type: Scalars['String']
}

export type CategorySlugChangedSlugArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type CategorySlugChangedOldSlugArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type ChangeStagedOrderCustomLineItemMoneyOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    customLineItemId: Scalars['String']
    money: BaseMoney
  }

export type ChangeStagedOrderCustomLineItemQuantityOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    customLineItemId: Scalars['String']
    quantity: Scalars['Long']
  }

export type ChangeStagedOrderLineItemQuantityOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    lineItemId: Scalars['String']
    quantity: Scalars['Long']
    externalPrice?: Maybe<BaseMoney>
    externalTotalPrice?: Maybe<ExternalLineItemTotalPrice>
  }

export type ChangeStagedOrderOrderStateOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    orderState: OrderState
  }

export type ChangeStagedOrderPaymentStateOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    paymentState: PaymentState
  }

export type ChangeStagedOrderShipmentStateOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    shipmentState: ShipmentState
  }

export type ChangeStagedOrderTaxCalculationModeOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    taxCalculationMode: TaxCalculationMode
  }

export type ChangeStagedOrderTaxModeOutput = StagedOrderUpdateActionOutput & {
  type: Scalars['String']
  taxMode: TaxMode
}

export type ChangeStagedOrderTaxRoundingModeOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    taxRoundingMode: RoundingMode
  }

export type Channel = Versioned &
  ReviewTarget &
  ReferenceExpandable & {
    id: Scalars['String']
    version: Scalars['Long']
    key: Scalars['String']
    roles: Array<ChannelRole>
    name?: Maybe<Scalars['String']>
    nameAllLocales?: Maybe<Array<LocalizedString>>
    description?: Maybe<Scalars['String']>
    descriptionAllLocales?: Maybe<Array<LocalizedString>>
    address?: Maybe<Address>
    geoLocation?: Maybe<Geometry>
    createdAt: Scalars['DateTime']
    lastModifiedAt: Scalars['DateTime']
    reviewRatingStatistics?: Maybe<ReviewRatingStatistics>
    custom?: Maybe<CustomFieldsType>
    createdBy?: Maybe<Initiator>
    lastModifiedBy?: Maybe<Initiator>
  }

export type ChannelNameArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type ChannelDescriptionArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type ChannelReferenceIdentifier = {
  typeId: Scalars['String']
  id?: Maybe<Scalars['String']>
  key?: Maybe<Scalars['String']>
}

export enum ChannelRole {
  /** Role tells that this channel can be used to track inventory entries.Channels with this role can be treated as warehouses */
  InventorySupply = 'InventorySupply',
  /** Role tells that this channel can be used to expose products to a specific distribution channel. It can be used by the cart to select a product price. */
  ProductDistribution = 'ProductDistribution',
  /** Role tells that this channel can be used to track order export activities. */
  OrderExport = 'OrderExport',
  /** Role tells that this channel can be used to track order import activities. */
  OrderImport = 'OrderImport',
  /** This role can be combined with some other roles (e.g. with `InventorySupply`) to represent the fact that this particular channel is the primary/master channel among the channels of the same type. */
  Primary = 'Primary',
}

export type ClassificationShippingRateInput = ShippingRateInput & {
  key: Scalars['String']
  type: Scalars['String']
  labelAllLocales: Array<LocalizedString>
  label?: Maybe<Scalars['String']>
}

export type ClassificationShippingRateInputLabelArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type ClassificationShippingRateInputDraftOutput =
  ShippingRateInputDraftOutput & {
    key: Scalars['String']
    type: Scalars['String']
  }

/** [ISO 3166-1](http://en.wikipedia.org/wiki/ISO_3166-1) country code. */
export enum Country {
  Af = 'AF',
  Al = 'AL',
  Dz = 'DZ',
  As = 'AS',
  Ad = 'AD',
  Ao = 'AO',
  Ai = 'AI',
  Aq = 'AQ',
  Ag = 'AG',
  Ar = 'AR',
  Am = 'AM',
  Aw = 'AW',
  Au = 'AU',
  At = 'AT',
  Az = 'AZ',
  Bs = 'BS',
  Bh = 'BH',
  Bd = 'BD',
  Bb = 'BB',
  By = 'BY',
  Be = 'BE',
  Bz = 'BZ',
  Bj = 'BJ',
  Bm = 'BM',
  Bt = 'BT',
  Bo = 'BO',
  Ba = 'BA',
  Bw = 'BW',
  Bv = 'BV',
  Br = 'BR',
  Io = 'IO',
  Bn = 'BN',
  Bg = 'BG',
  Bf = 'BF',
  Bi = 'BI',
  Kh = 'KH',
  Cm = 'CM',
  Ca = 'CA',
  Cv = 'CV',
  Ky = 'KY',
  Cf = 'CF',
  Td = 'TD',
  Cl = 'CL',
  Cn = 'CN',
  Cx = 'CX',
  Cc = 'CC',
  Co = 'CO',
  Km = 'KM',
  Cg = 'CG',
  Cd = 'CD',
  Ck = 'CK',
  Cr = 'CR',
  Ci = 'CI',
  Hr = 'HR',
  Cu = 'CU',
  Cy = 'CY',
  Cz = 'CZ',
  Dk = 'DK',
  Dj = 'DJ',
  Dm = 'DM',
  Do = 'DO',
  Ec = 'EC',
  Eg = 'EG',
  Sv = 'SV',
  Gq = 'GQ',
  Er = 'ER',
  Ee = 'EE',
  Et = 'ET',
  Fk = 'FK',
  Fo = 'FO',
  Fj = 'FJ',
  Fi = 'FI',
  Fr = 'FR',
  Gf = 'GF',
  Pf = 'PF',
  Tf = 'TF',
  Ga = 'GA',
  Gm = 'GM',
  Ge = 'GE',
  De = 'DE',
  Gh = 'GH',
  Gi = 'GI',
  Gr = 'GR',
  Gl = 'GL',
  Gd = 'GD',
  Gp = 'GP',
  Gu = 'GU',
  Gt = 'GT',
  Gg = 'GG',
  Gn = 'GN',
  Gw = 'GW',
  Gy = 'GY',
  Ht = 'HT',
  Hm = 'HM',
  Va = 'VA',
  Hn = 'HN',
  Hk = 'HK',
  Hu = 'HU',
  Is = 'IS',
  In = 'IN',
  Id = 'ID',
  Ir = 'IR',
  Iq = 'IQ',
  Ie = 'IE',
  Im = 'IM',
  Il = 'IL',
  It = 'IT',
  Jm = 'JM',
  Jp = 'JP',
  Je = 'JE',
  Jo = 'JO',
  Kz = 'KZ',
  Ke = 'KE',
  Ki = 'KI',
  Kp = 'KP',
  Kr = 'KR',
  Kw = 'KW',
  Kg = 'KG',
  La = 'LA',
  Lv = 'LV',
  Lb = 'LB',
  Ls = 'LS',
  Lr = 'LR',
  Ly = 'LY',
  Li = 'LI',
  Lt = 'LT',
  Lu = 'LU',
  Mo = 'MO',
  Mk = 'MK',
  Mg = 'MG',
  Mw = 'MW',
  My = 'MY',
  Mv = 'MV',
  Ml = 'ML',
  Mt = 'MT',
  Mh = 'MH',
  Mq = 'MQ',
  Mr = 'MR',
  Mu = 'MU',
  Yt = 'YT',
  Mx = 'MX',
  Fm = 'FM',
  Md = 'MD',
  Mc = 'MC',
  Mn = 'MN',
  Me = 'ME',
  Ms = 'MS',
  Ma = 'MA',
  Mz = 'MZ',
  Mm = 'MM',
  Na = 'NA',
  Nr = 'NR',
  Np = 'NP',
  Nl = 'NL',
  An = 'AN',
  Nc = 'NC',
  Nz = 'NZ',
  Ni = 'NI',
  Ne = 'NE',
  Ng = 'NG',
  Nu = 'NU',
  Nf = 'NF',
  Mp = 'MP',
  No = 'NO',
  Om = 'OM',
  Pk = 'PK',
  Pw = 'PW',
  Ps = 'PS',
  Pa = 'PA',
  Pg = 'PG',
  Py = 'PY',
  Pe = 'PE',
  Ph = 'PH',
  Pn = 'PN',
  Pl = 'PL',
  Pt = 'PT',
  Pr = 'PR',
  Qa = 'QA',
  Re = 'RE',
  Ro = 'RO',
  Ru = 'RU',
  Rw = 'RW',
  Sh = 'SH',
  Kn = 'KN',
  Lc = 'LC',
  Pm = 'PM',
  Vc = 'VC',
  Ws = 'WS',
  Sm = 'SM',
  St = 'ST',
  Sa = 'SA',
  Sn = 'SN',
  Rs = 'RS',
  Sc = 'SC',
  Sl = 'SL',
  Sg = 'SG',
  Sk = 'SK',
  Si = 'SI',
  Sb = 'SB',
  So = 'SO',
  Za = 'ZA',
  Gs = 'GS',
  Es = 'ES',
  Lk = 'LK',
  Sd = 'SD',
  Sr = 'SR',
  Sj = 'SJ',
  Sz = 'SZ',
  Se = 'SE',
  Ch = 'CH',
  Sy = 'SY',
  Tw = 'TW',
  Tj = 'TJ',
  Tz = 'TZ',
  Th = 'TH',
  Tl = 'TL',
  Tg = 'TG',
  Tk = 'TK',
  To = 'TO',
  Tt = 'TT',
  Tn = 'TN',
  Tr = 'TR',
  Tm = 'TM',
  Tc = 'TC',
  Tv = 'TV',
  Ug = 'UG',
  Ua = 'UA',
  Ae = 'AE',
  Gb = 'GB',
  Us = 'US',
  Um = 'UM',
  Uy = 'UY',
  Uz = 'UZ',
  Vu = 'VU',
  Ve = 'VE',
  Vn = 'VN',
  Vg = 'VG',
  Vi = 'VI',
  Wf = 'WF',
  Eh = 'EH',
  Ye = 'YE',
  Zm = 'ZM',
  Zw = 'ZW',
}

export type CustomFieldsCommand = {
  typeId?: Maybe<Scalars['String']>
  typeKey?: Maybe<Scalars['String']>
  fields: Scalars['Json']
  typeResId?: Maybe<ResourceIdentifier>
}

export type CustomFieldsType = {
  typeRef: Reference
  type?: Maybe<TypeDefinition>
  /** This field contains non-typed data. */
  customFieldsRaw?: Maybe<Array<RawCustomField>>
}

export type CustomFieldsTypeCustomFieldsRawArgs = {
  includeNames?: InputMaybe<Array<Scalars['String']>>
  excludeNames?: InputMaybe<Array<Scalars['String']>>
}

/** A custom line item is a generic item that can be added to the cart but is not bound to a product. You can use it for discounts (negative money), vouchers, complex cart rules, additional services or fees. You control the lifecycle of this item. */
export type CustomLineItem = {
  id: Scalars['String']
  name?: Maybe<Scalars['String']>
  nameAllLocales: Array<LocalizedString>
  money: BaseMoney
  totalPrice: Money
  slug: Scalars['String']
  quantity: Scalars['Long']
  state: Array<ItemState>
  taxCategory?: Maybe<TaxCategory>
  taxCategoryRef?: Maybe<Reference>
  taxRate?: Maybe<TaxRate>
  taxedPrice?: Maybe<TaxedItemPrice>
  discountedPricePerQuantity: Array<DiscountedLineItemPriceForQuantity>
  custom?: Maybe<CustomFieldsType>
  shippingDetails?: Maybe<ItemShippingDetails>
  priceMode: CustomLineItemPriceMode
}

/** A custom line item is a generic item that can be added to the cart but is not bound to a product. You can use it for discounts (negative money), vouchers, complex cart rules, additional services or fees. You control the lifecycle of this item. */
export type CustomLineItemNameArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type CustomLineItemDraftOutput = {
  money: BaseMoney
  slug: Scalars['String']
  externalTaxRate?: Maybe<ExternalTaxRateDraftOutput>
  quantity?: Maybe<Scalars['Long']>
  custom?: Maybe<CustomFieldsCommand>
  shippingDetails?: Maybe<ItemShippingDetailsDraftOutput>
  priceMode: CustomLineItemPriceMode
  name?: Maybe<Scalars['String']>
  taxCategoryResId?: Maybe<ResourceIdentifier>
  nameAllLocales: Array<LocalizedString>
}

export type CustomLineItemDraftOutputNameArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export enum CustomLineItemPriceMode {
  /** Cart discounts are deactivated for the custom line items with this price mode. */
  External = 'External',
  /** This is the default mode. */
  Standard = 'Standard',
}

export type CustomLineItemReturnItem = ReturnItem & {
  type: Scalars['String']
  customLineItemId: Scalars['String']
  id: Scalars['String']
  quantity: Scalars['Long']
  comment?: Maybe<Scalars['String']>
  shipmentState: ReturnShipmentState
  paymentState: ReturnPaymentState
  custom?: Maybe<CustomFieldsType>
  lastModifiedAt: Scalars['DateTime']
  createdAt: Scalars['DateTime']
}

export type CustomLineItemStateTransition = MessagePayload &
  OrderMessagePayload & {
    customLineItemId: Scalars['String']
    transitionDate: Scalars['DateTime']
    quantity: Scalars['Long']
    fromState?: Maybe<State>
    toState?: Maybe<State>
    fromStateRef: Reference
    toStateRef: Reference
    type: Scalars['String']
  }

export type CustomLineItemsTarget = CartDiscountTarget & {
  predicate: Scalars['String']
  type: Scalars['String']
}

export type CustomObject = Versioned &
  ReferenceExpandable & {
    container: Scalars['String']
    key: Scalars['String']
    value: Scalars['Json']
    id: Scalars['String']
    version: Scalars['Long']
    createdAt: Scalars['DateTime']
    lastModifiedAt: Scalars['DateTime']
    createdBy?: Maybe<Initiator>
    lastModifiedBy?: Maybe<Initiator>
  }

export type CustomSuggestTokenizer = SuggestTokenizer & {
  inputs: Array<Scalars['String']>
  type: Scalars['String']
}

/** A customer is a person purchasing products. Carts, Orders and Reviews can be associated to a customer. */
export type Customer = Versioned &
  ReferenceExpandable & {
    customerNumber?: Maybe<Scalars['String']>
    email: Scalars['String']
    addresses: Array<Address>
    defaultShippingAddressId?: Maybe<Scalars['String']>
    defaultBillingAddressId?: Maybe<Scalars['String']>
    shippingAddressIds: Array<Scalars['String']>
    billingAddressIds: Array<Scalars['String']>
    isEmailVerified: Scalars['Boolean']
    customerGroupRef?: Maybe<Reference>
    externalId?: Maybe<Scalars['String']>
    key?: Maybe<Scalars['String']>
    authenticationMode?: Maybe<AuthenticationMode>
    firstName?: Maybe<Scalars['String']>
    lastName?: Maybe<Scalars['String']>
    middleName?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
    locale?: Maybe<Scalars['Locale']>
    salutation?: Maybe<Scalars['String']>
    dateOfBirth?: Maybe<Scalars['Date']>
    companyName?: Maybe<Scalars['String']>
    vatId?: Maybe<Scalars['String']>
    password?: Maybe<Scalars['String']>
    customerGroup?: Maybe<CustomerGroup>
    defaultShippingAddress?: Maybe<Address>
    defaultBillingAddress?: Maybe<Address>
    shippingAddresses?: Maybe<Array<Maybe<Address>>>
    billingAddresses?: Maybe<Array<Maybe<Address>>>
    storesRef?: Maybe<Array<Maybe<KeyReference>>>
    stores: Array<Store>
    custom?: Maybe<CustomFieldsType>
    id: Scalars['String']
    version: Scalars['Long']
    createdAt: Scalars['DateTime']
    lastModifiedAt: Scalars['DateTime']
    createdBy?: Maybe<Initiator>
    lastModifiedBy?: Maybe<Initiator>
  }

export type CustomerAddressAdded = MessagePayload & {
  address: Address
  type: Scalars['String']
}

export type CustomerAddressChanged = MessagePayload & {
  address: Address
  type: Scalars['String']
}

export type CustomerAddressRemoved = MessagePayload & {
  address: Address
  type: Scalars['String']
}

export type CustomerCompanyNameSet = MessagePayload & {
  companyName?: Maybe<Scalars['String']>
  type: Scalars['String']
}

export type CustomerCreated = MessagePayload & {
  customer: Customer
  type: Scalars['String']
}

export type CustomerDateOfBirthSet = MessagePayload & {
  dateOfBirth?: Maybe<Scalars['Date']>
  type: Scalars['String']
}

export type CustomerDeleted = MessagePayload & {
  type: Scalars['String']
}

export type CustomerEmailChanged = MessagePayload & {
  email: Scalars['String']
  type: Scalars['String']
}

export type CustomerEmailTokenCreated = MessagePayload & {
  customerId: Scalars['String']
  expiresAt: Scalars['DateTime']
  type: Scalars['String']
}

export type CustomerEmailVerified = MessagePayload & {
  type: Scalars['String']
}

export type CustomerFirstNameSet = MessagePayload & {
  firstName?: Maybe<Scalars['String']>
  type: Scalars['String']
}

/** A customer can be a member in a customer group (e.g. reseller, gold member). A customer group can be used in price calculations with special prices being assigned to certain customer groups. */
export type CustomerGroup = Versioned &
  ReferenceExpandable & {
    id: Scalars['String']
    version: Scalars['Long']
    name: Scalars['String']
    key?: Maybe<Scalars['String']>
    createdAt: Scalars['DateTime']
    lastModifiedAt: Scalars['DateTime']
    custom?: Maybe<CustomFieldsType>
    createdBy?: Maybe<Initiator>
    lastModifiedBy?: Maybe<Initiator>
  }

export type CustomerGroupReferenceIdentifier = {
  typeId: Scalars['String']
  id?: Maybe<Scalars['String']>
  key?: Maybe<Scalars['String']>
}

export type CustomerGroupSet = MessagePayload & {
  customerGroup?: Maybe<CustomerGroup>
  customerGroupRef?: Maybe<Reference>
  type: Scalars['String']
}

export type CustomerLastNameSet = MessagePayload & {
  lastName?: Maybe<Scalars['String']>
  type: Scalars['String']
}

export type CustomerPasswordTokenCreated = MessagePayload & {
  customerId: Scalars['String']
  expiresAt: Scalars['DateTime']
  type: Scalars['String']
}

export type CustomerPasswordUpdated = MessagePayload & {
  reset: Scalars['Boolean']
  type: Scalars['String']
}

export type CustomerTitleSet = MessagePayload & {
  title?: Maybe<Scalars['String']>
  type: Scalars['String']
}

export type DateAttributeDefinitionType = AttributeDefinitionType & {
  name: Scalars['String']
}

export type DateTimeAttributeDefinitionType = AttributeDefinitionType & {
  name: Scalars['String']
}

export type DateTimeType = FieldType & {
  name: Scalars['String']
}

export type DateType = FieldType & {
  name: Scalars['String']
}

export type Delivery = {
  id: Scalars['String']
  createdAt: Scalars['DateTime']
  items: Array<DeliveryItem>
  parcels: Array<Parcel>
  address?: Maybe<Address>
  custom?: Maybe<CustomFieldsType>
}

export type DeliveryAdded = MessagePayload &
  OrderMessagePayload & {
    delivery: Delivery
    shippingKey?: Maybe<Scalars['String']>
    type: Scalars['String']
  }

export type DeliveryAddressSet = MessagePayload &
  OrderMessagePayload & {
    deliveryId: Scalars['String']
    address?: Maybe<Address>
    oldAddress?: Maybe<Address>
    shippingKey?: Maybe<Scalars['String']>
    type: Scalars['String']
  }

export type DeliveryItem = {
  id: Scalars['String']
  quantity: Scalars['Long']
}

export type DeliveryItemsUpdated = MessagePayload &
  OrderMessagePayload & {
    deliveryId: Scalars['String']
    items: Array<DeliveryItem>
    oldItems: Array<DeliveryItem>
    shippingKey?: Maybe<Scalars['String']>
    type: Scalars['String']
  }

export enum DeliveryOption {
  Standard = 'STANDARD',
  Guarantee = 'GUARANTEE',
  Rush = 'RUSH',
}

export type DeliveryRemoved = MessagePayload &
  OrderMessagePayload & {
    delivery: Delivery
    shippingKey?: Maybe<Scalars['String']>
    type: Scalars['String']
  }

export type Design = {
  id: Scalars['Int']
  name: Scalars['String']
  filename: Scalars['String']
  slug?: Maybe<Scalars['String']>
  for_sale?: Maybe<Scalars['Boolean']>
  user_favorite?: Maybe<Scalars['Boolean']>
  favorite_count?: Maybe<Scalars['Int']>
  short_description?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  for_sale_as_wallpaper?: Maybe<Scalars['Boolean']>
  for_sale_as_gift_wrap?: Maybe<Scalars['Boolean']>
  for_sale_as_fabric?: Maybe<Scalars['Boolean']>
  for_sale_as_home_good?: Maybe<Scalars['Boolean']>
  for_sale_as_tea_towel?: Maybe<Scalars['Boolean']>
  all_locale_slugs?: Maybe<DesignLocaleSlugs>
  repeat_type?: Maybe<Scalars['String']>
  dpi?: Maybe<Scalars['Int']>
  thumbnail_url: Scalars['String']
  designer?: Maybe<Scalars['String']>
  designer_url?: Maybe<Scalars['String']>
}

export type DesignLocaleSlugs = {
  en?: Maybe<Scalars['String']>
  de?: Maybe<Scalars['String']>
  es?: Maybe<Scalars['String']>
  fr?: Maybe<Scalars['String']>
}

export type DesignSalabilityRequirements = {
  artistID: Scalars['Int']
  designID: Scalars['Int']
  isCentered: Scalars['Boolean']
  isDeleted: Scalars['Boolean']
  isEnabledForHomeGoods: Scalars['Boolean']
  isExclusiveToFTTWH: Scalars['Boolean']
  isForSale: Scalars['Boolean']
  isForSaleAsFabric: Scalars['Boolean']
  isForSaleAsWallpaper: Scalars['Boolean']
  isPrivate: Scalars['Boolean']
}

export type Dimensions = {
  width: Scalars['Int']
  height: Scalars['Int']
}

/** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
export type DirectDiscount = {
  id: Scalars['String']
  value: CartDiscountValue
  target?: Maybe<CartDiscountTarget>
}

/** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
export type DirectDiscountDraftOutput = {
  value: CartDiscountValue
  target?: Maybe<CartDiscountTarget>
}

/** With discount codes it is possible to give specific cart discounts to an eligible amount of users. They are defined by a string value which can be added to a cart so that specific cart discounts can be applied to the cart. */
export type DiscountCode = Versioned &
  ReferenceExpandable & {
    code: Scalars['String']
    isActive: Scalars['Boolean']
    maxApplications?: Maybe<Scalars['Long']>
    maxApplicationsPerCustomer?: Maybe<Scalars['Long']>
    cartPredicate?: Maybe<Scalars['String']>
    applicationVersion?: Maybe<Scalars['Long']>
    validFrom?: Maybe<Scalars['DateTime']>
    validUntil?: Maybe<Scalars['DateTime']>
    groups: Array<Scalars['String']>
    name?: Maybe<Scalars['String']>
    description?: Maybe<Scalars['String']>
    cartDiscounts: Array<CartDiscount>
    referenceRefs: Array<Reference>
    nameAllLocales?: Maybe<Array<LocalizedString>>
    descriptionAllLocales?: Maybe<Array<LocalizedString>>
    custom?: Maybe<CustomFieldsType>
    /** How many times this discount code was applied (only applications that were part of a successful checkout are considered) */
    applicationCount: Scalars['Long']
    cartDiscountRefs: Array<Reference>
    id: Scalars['String']
    version: Scalars['Long']
    createdAt: Scalars['DateTime']
    lastModifiedAt: Scalars['DateTime']
    createdBy?: Maybe<Initiator>
    lastModifiedBy?: Maybe<Initiator>
  }

/** With discount codes it is possible to give specific cart discounts to an eligible amount of users. They are defined by a string value which can be added to a cart so that specific cart discounts can be applied to the cart. */
export type DiscountCodeNameArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

/** With discount codes it is possible to give specific cart discounts to an eligible amount of users. They are defined by a string value which can be added to a cart so that specific cart discounts can be applied to the cart. */
export type DiscountCodeDescriptionArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type DiscountCodeInfo = {
  discountCodeRef: Reference
  state?: Maybe<DiscountCodeState>
  discountCode?: Maybe<DiscountCode>
}

export enum DiscountCodeState {
  /** The discount code is active and none of the discounts were applied because the discount application was stopped by one discount that has the StackingMode of StopAfterThisDiscount defined */
  ApplicationStoppedByPreviousDiscount = 'ApplicationStoppedByPreviousDiscount',
  /** The discount code is not valid or it does not contain any valid cart discounts. Validity is determined based on the validFrom and validUntil dates */
  NotValid = 'NotValid',
  /** maxApplications or maxApplicationsPerCustomer for discountCode has been reached. */
  MaxApplicationReached = 'MaxApplicationReached',
  /** The discount code is active and it contains at least one active and valid CartDiscount. The discount code cartPredicate matches the cart and at least one of the contained active discount’s cart predicates matches the cart. */
  MatchesCart = 'MatchesCart',
  /** The discount code is active and it contains at least one active and valid CartDiscount. But its cart predicate does not match the cart or none of the contained active discount’s cart predicates match the cart */
  DoesNotMatchCart = 'DoesNotMatchCart',
  /** The discount code is not active or it does not contain any active cart discounts. */
  NotActive = 'NotActive',
}

export type DiscountedLineItemPortion = {
  discount?: Maybe<CartDiscount>
  discountRef: Reference
  discountedAmount: BaseMoney
}

export type DiscountedLineItemPrice = {
  value: BaseMoney
  includedDiscounts: Array<DiscountedLineItemPortion>
}

export type DiscountedLineItemPriceForQuantity = {
  quantity: Scalars['Long']
  discountedPrice: DiscountedLineItemPrice
}

export type DiscountedProductPriceValue = {
  value: BaseMoney
  discountRef: Reference
  discount?: Maybe<ProductDiscount>
}

export type EnumAttributeDefinitionType = AttributeDefinitionType & {
  values: PlainEnumValueResult
  name: Scalars['String']
}

export type EnumAttributeDefinitionTypeValuesArgs = {
  includeKeys?: InputMaybe<Array<Scalars['String']>>
  excludeKeys?: InputMaybe<Array<Scalars['String']>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  sort?: InputMaybe<Array<Scalars['String']>>
}

export type EnumType = FieldType & {
  values: Array<EnumValue>
  name: Scalars['String']
}

export type EnumValue = {
  key: Scalars['String']
  label: Scalars['String']
}

export type Extension = Versioned &
  ReferenceExpandable & {
    key?: Maybe<Scalars['String']>
    destination: ExtensionDestination
    triggers: Array<Trigger>
    timeoutInMs?: Maybe<Scalars['Int']>
    id: Scalars['String']
    version: Scalars['Long']
    createdAt: Scalars['DateTime']
    lastModifiedAt: Scalars['DateTime']
    createdBy?: Maybe<Initiator>
    lastModifiedBy?: Maybe<Initiator>
  }

export type ExtensionDestination = {
  type: Scalars['String']
}

export type ExternalDiscountValue = ProductDiscountValue & {
  type: Scalars['String']
}

export type ExternalLineItemTotalPrice = {
  price: BaseMoney
  totalPrice: Money
}

export type ExternalTaxAmountDraftOutput = {
  totalGross: Money
  taxRate: ExternalTaxRateDraftOutput
}

export type ExternalTaxRateDraftOutput = {
  name: Scalars['String']
  amount?: Maybe<Scalars['Float']>
  country: Country
  state?: Maybe<Scalars['String']>
  subRates: Array<SubRate>
  includedInPrice: Scalars['Boolean']
}

export type Fabric = {
  id: Scalars['Int']
  type: Scalars['String']
  code: Scalars['String']
  size?: Maybe<Scalars['String']>
  width?: Maybe<FabricWidth>
  height?: Maybe<FabricHeight>
}

export type FabricHeight = {
  inches: Scalars['Float']
  millimeters: Scalars['Int']
}

export type FabricWidth = {
  inches: Scalars['Float']
  millimeters: Scalars['Int']
}

export type Favorite = {
  favoriteable?: Maybe<Scalars['Boolean']>
  favorited?: Maybe<Scalars['Boolean']>
}

export type Favorites = {
  design_ids?: Maybe<Array<Maybe<Scalars['Int']>>>
}

/** Field definitions describe custom fields and allow you to define some meta-information associated with the field. */
export type FieldDefinition = {
  name: Scalars['String']
  required: Scalars['Boolean']
  inputHint: TextInputHint
  label?: Maybe<Scalars['String']>
  labelAllLocales: Array<LocalizedString>
  type: FieldType
}

/** Field definitions describe custom fields and allow you to define some meta-information associated with the field. */
export type FieldDefinitionLabelArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type FieldType = {
  name: Scalars['String']
}

export type FixedPriceDiscountValue = CartDiscountValue & {
  money: Array<Money>
  type: Scalars['String']
}

export type Geometry = {
  type: Scalars['String']
}

export type GiftLineItemValue = CartDiscountValue & {
  type: Scalars['String']
  variantId: Scalars['Int']
  productRef: ProductReferenceIdentifier
  distributionChannelRef?: Maybe<ChannelReferenceIdentifier>
  supplyChannelRef?: Maybe<ChannelReferenceIdentifier>
}

export type HighPrecisionMoney = BaseMoney & {
  type: Scalars['String']
  currencyCode: Scalars['Currency']
  preciseAmount: Scalars['Long']
  centAmount: Scalars['Long']
  fractionDigits: Scalars['Int']
}

export type HomeGood = {
  code: Scalars['String']
  weight: Scalars['String']
  home_good_type?: Maybe<HomeGoodType>
}

export type HomeGoodItem = {
  home_good?: Maybe<HomeGood>
  design?: Maybe<Design>
}

export type HomeGoodType = {
  code: Scalars['String']
}

export type HttpDestination = ExtensionDestination & {
  type: Scalars['String']
  url: Scalars['String']
  authentication?: Maybe<HttpDestinationAuthentication>
}

export type HttpDestinationAuthentication = {
  type: Scalars['String']
}

export type Image = {
  url: Scalars['String']
  dimensions: Dimensions
  label?: Maybe<Scalars['String']>
}

export type ImportStagedOrderCustomLineItemStateOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    customLineItemId: Scalars['String']
    state: Scalars['Set']
  }

export type ImportStagedOrderLineItemStateOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    lineItemId: Scalars['String']
    state: Scalars['Set']
  }

/** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
export type IndividualProductSelectionCreatedPayload =
  ProductSelectionCreatedPayload & {
    custom?: Maybe<CustomFieldsType>
    name?: Maybe<Scalars['String']>
    nameAllLocales: Array<LocalizedString>
    type: Scalars['String']
  }

/** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
export type IndividualProductSelectionCreatedPayloadNameArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type Initiator = {
  isPlatformClient?: Maybe<Scalars['Boolean']>
  externalUserId?: Maybe<Scalars['String']>
  anonymousId?: Maybe<Scalars['String']>
  clientId?: Maybe<Scalars['String']>
  customerRef?: Maybe<Reference>
  userRef?: Maybe<Reference>
}

export type InterfaceInteractionsRaw = {
  typeRef: Reference
  type?: Maybe<TypeDefinition>
  fields: Array<RawCustomField>
}

export type InterfaceInteractionsRawFieldsArgs = {
  includeNames?: InputMaybe<Array<Scalars['String']>>
  excludeNames?: InputMaybe<Array<Scalars['String']>>
}

export type InterfaceInteractionsRawResult = {
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  total: Scalars['Int']
  results: Array<InterfaceInteractionsRaw>
}

/** Inventory allows you to track stock quantity per SKU and optionally per supply channel */
export type InventoryEntry = Versioned &
  ReferenceExpandable & {
    sku: Scalars['String']
    quantityOnStock: Scalars['Long']
    availableQuantity: Scalars['Long']
    key?: Maybe<Scalars['String']>
    restockableInDays?: Maybe<Scalars['Int']>
    expectedDelivery?: Maybe<Scalars['DateTime']>
    supplyChannel?: Maybe<Channel>
    supplyChannelRef?: Maybe<Reference>
    custom?: Maybe<CustomFieldsType>
    id: Scalars['String']
    version: Scalars['Long']
    createdAt: Scalars['DateTime']
    lastModifiedAt: Scalars['DateTime']
    createdBy?: Maybe<Initiator>
    lastModifiedBy?: Maybe<Initiator>
  }

export type InventoryEntryCreated = MessagePayload & {
  inventoryEntry: InventoryEntryCreatedContent
  type: Scalars['String']
}

export type InventoryEntryCreatedContent = {
  inventoryEntryId: Scalars['String']
  sku: Scalars['String']
  quantityOnStock: Scalars['Long']
  key?: Maybe<Scalars['String']>
  restockableInDays?: Maybe<Scalars['Int']>
  expectedDelivery?: Maybe<Scalars['DateTime']>
  messageId?: Maybe<MessageId>
  supplyChannel?: Maybe<Channel>
  supplyChannelRef?: Maybe<Reference>
  custom?: Maybe<CustomFieldsType>
}

export type InventoryEntryDeleted = MessagePayload & {
  sku: Scalars['String']
  supplyChannel?: Maybe<Channel>
  supplyChannelRef?: Maybe<Reference>
  type: Scalars['String']
}

export type InventoryEntryQuantitySet = MessagePayload & {
  oldQuantityOnStock: Scalars['Long']
  newQuantityOnStock: Scalars['Long']
  oldAvailableQuantity: Scalars['Long']
  newAvailableQuantity: Scalars['Long']
  supplyChannel?: Maybe<Channel>
  supplyChannelRef?: Maybe<Reference>
  type: Scalars['String']
}

export enum InventoryMode {
  /**
   * Adding items to cart and ordering is independent of inventory. No inventory checks or modifications.
   * This is the default mode for a new cart.
   */
  None = 'None',
  /**
   * Creating an order will fail with an OutOfStock error if an unavailable line item exists. Line items in the cart
   * are only reserved for the duration of the ordering transaction.
   */
  ReserveOnOrder = 'ReserveOnOrder',
  /**
   * Orders are tracked on inventory. That means, ordering a LineItem will decrement the available quantity on the
   * respective InventoryEntry. Creating an order will succeed even if the line item’s available quantity is zero or
   * negative. But creating an order will fail with an OutOfStock error if no matching inventory entry exists for a
   * line item.
   */
  TrackOnly = 'TrackOnly',
}

export type ItemShippingDetails = {
  targets: Array<ItemShippingTarget>
  valid: Scalars['Boolean']
}

export type ItemShippingDetailsDraftOutput = {
  targets: Array<ItemShippingTarget>
}

export type ItemShippingTarget = {
  addressKey: Scalars['String']
  quantity: Scalars['Long']
  shippingMethodKey?: Maybe<Scalars['String']>
}

export type ItemState = {
  quantity: Scalars['Long']
  stateRef: Reference
  state?: Maybe<State>
}

export type KeyReference = {
  typeId: Scalars['String']
  key: Scalars['String']
}

export type LegacyAfterPay = {
  eligible?: Maybe<Scalars['Boolean']>
  price_valid?: Maybe<Scalars['Boolean']>
  currency_valid?: Maybe<Scalars['Boolean']>
  installments?: Maybe<LegacyAfterPayInstallments>
  available?: Maybe<Scalars['Boolean']>
  config?: Maybe<LegacyAfterPayConfig>
}

export type LegacyAfterPayConfig = {
  min_value?: Maybe<Scalars['Float']>
  max_value?: Maybe<Scalars['Float']>
  currency?: Maybe<AlpCurrency>
}

export type LegacyAfterPayInstallmentType = {
  summary?: Maybe<Scalars['Float']>
  all?: Maybe<Array<Maybe<Scalars['Float']>>>
}

export type LegacyAfterPayInstallments = {
  from_raw_total?: Maybe<LegacyAfterPayInstallmentType>
  from_subtotal_without_shipping?: Maybe<LegacyAfterPayInstallmentType>
}

export type LegacyCart = {
  id: Scalars['Int']
  user_id: Scalars['Int']
  status: CartStatus
  shipping_address?: Maybe<LegacyShippingAddress>
  order_items?: Maybe<Array<Maybe<OrderLineItem>>>
  pricing?: Maybe<LegacyPricing>
  hide_prices?: Maybe<Scalars['Boolean']>
  delivery_options?: Maybe<DeliveryOption>
  factory?: Maybe<Scalars['String']>
  payment_options?: Maybe<LegacyPaymentOptions>
}

export type LegacyIdMe = {
  api_key?: Maybe<Scalars['String']>
  available?: Maybe<Scalars['Boolean']>
}

export type LegacyPaymentOptions = {
  afterpay?: Maybe<LegacyAfterPay>
  id_me?: Maybe<LegacyIdMe>
}

export type LegacyPricing = {
  contains_subscription_item: Scalars['Boolean']
  tax_applicable: Scalars['Boolean']
  spoondollars?: Maybe<SpoonDollars>
  raw_vat?: Maybe<Scalars['Float']>
  raw_shipping?: Maybe<Scalars['Float']>
  raw_shipping_usd?: Maybe<Scalars['Float']>
  vat_exempt?: Maybe<Scalars['Boolean']>
  currency?: Maybe<AlpCurrency>
  exhange_rate?: Maybe<Scalars['Float']>
  vat_rate?: Maybe<Scalars['Float']>
  raw_subtotal_without_shipping_vat?: Maybe<Scalars['Float']>
  raw_subtotal_without_shipping_vat_usd?: Maybe<Scalars['Float']>
  raw_subtotal_without_shipping?: Maybe<Scalars['Float']>
  raw_subtotal_without_vat?: Maybe<Scalars['Float']>
  raw_subtotal_without_vat_usd?: Maybe<Scalars['Float']>
  raw_subtotal_before_payment?: Maybe<Scalars['Float']>
  raw_subtotal_before_payment_usd?: Maybe<Scalars['Float']>
  raw_subtotal_without_shipping_vat_promo?: Maybe<Scalars['Float']>
  raw_subtotal_without_shipping_promo?: Maybe<Scalars['Float']>
  raw_total?: Maybe<Scalars['Float']>
  raw_total_usd?: Maybe<Scalars['Float']>
  partial_payment?: Maybe<Scalars['Boolean']>
}

export type LegacyShippingAddress = {
  required: Scalars['Boolean']
  ship_to: Scalars['String']
  address1: Scalars['String']
  city: Scalars['String']
  state: Scalars['String']
  country: Scalars['String']
  phone_number: Scalars['Int']
}

/**
 * A line item is a snapshot of a product variant at the time it was added to the cart.
 * Since a product variant may change at any time, the ProductVariant data is copied into the field variant.
 * The relation to the Product is kept but the line item will not automatically update if the product variant changes.
 * On the cart, the line item can be updated manually. The productSlug refers to the current version of the product.
 * It can be used to link to the product. If the product has been deleted, the line item remains but refers to a
 * non-existent product and the productSlug is left empty.
 * Please also note that creating an order is impossible if the product or product variant a line item relates to has been deleted.
 */
export type LineItem = {
  id: Scalars['String']
  productId: Scalars['String']
  productKey?: Maybe<Scalars['String']>
  name: Scalars['Json']
  nameAllLocales: Array<LocalizedString>
  productSlug: LocalizedString
  productSlugAllLocales: Scalars['Json']
  productType?: Maybe<ProductTypeDefinition>
  productTypeRef?: Maybe<Reference>
  variant?: Maybe<ProductVariant>
  price: ProductPrice
  taxedPrice?: Maybe<TaxedItemPrice>
  totalPrice?: Maybe<Money>
  quantity: Scalars['Long']
  addedAt?: Maybe<Scalars['DateTime']>
  lastModifiedAt?: Maybe<Scalars['DateTime']>
  state: Array<ItemState>
  taxRate?: Maybe<TaxRate>
  supplyChannel?: Maybe<Channel>
  supplyChannelRef?: Maybe<Reference>
  distributionChannel?: Maybe<Channel>
  distributionChannelRef?: Maybe<Reference>
  discountedPricePerQuantity: Array<DiscountedLineItemPriceForQuantity>
  lineItemMode: LineItemMode
  priceMode: LineItemPriceMode
  custom?: Maybe<CustomFieldsType>
  inventoryMode?: Maybe<InventoryMode>
  shippingDetails?: Maybe<ItemShippingDetails>
}

export type LineItemDraftOutput = {
  productId?: Maybe<Scalars['String']>
  sku?: Maybe<Scalars['String']>
  quantity?: Maybe<Scalars['Long']>
  variantId?: Maybe<Scalars['Int']>
  custom?: Maybe<CustomFieldsCommand>
  externalTaxRate?: Maybe<ExternalTaxRateDraftOutput>
  externalPrice?: Maybe<BaseMoney>
  externalTotalPrice?: Maybe<ExternalLineItemTotalPrice>
  inventoryMode?: Maybe<InventoryMode>
  shippingDetails?: Maybe<ItemShippingDetailsDraftOutput>
  addedAt?: Maybe<Scalars['DateTime']>
  distributionChannelResId?: Maybe<ResourceIdentifier>
  supplyChannelResId?: Maybe<ResourceIdentifier>
}

export enum LineItemMode {
  /**
   * The line item was added automatically, because a discount has added a free gift to the cart.
   * The quantity can not be increased, and it won’t be merged when the same product variant is added.
   * If the gift is removed, an entry is added to the "refusedGifts" array and the discount won’t be applied again
   * to the cart. The price can not be changed externally.
   * All other updates, such as the ones related to custom fields, can be used.
   */
  GiftLineItem = 'GiftLineItem',
  /**
   * The line item was added during cart creation or with the update action addLineItem. Its quantity can be
   * changed without restrictions.
   */
  Standard = 'Standard',
}

export enum LineItemPriceMode {
  /** The price is selected form the product variant. This is the default mode. */
  Platform = 'Platform',
  /** The line item price was set externally. Cart discounts can apply to line items with this price mode. All update actions that change the quantity of a line item with this price mode require the externalPrice field to be given. */
  ExternalPrice = 'ExternalPrice',
  /** The line item price with the total was set externally. */
  ExternalTotal = 'ExternalTotal',
}

export type LineItemReturnItem = ReturnItem & {
  type: Scalars['String']
  lineItemId: Scalars['String']
  id: Scalars['String']
  quantity: Scalars['Long']
  comment?: Maybe<Scalars['String']>
  shipmentState: ReturnShipmentState
  paymentState: ReturnPaymentState
  custom?: Maybe<CustomFieldsType>
  lastModifiedAt: Scalars['DateTime']
  createdAt: Scalars['DateTime']
}

export type LineItemStateTransition = MessagePayload &
  OrderMessagePayload & {
    lineItemId: Scalars['String']
    transitionDate: Scalars['DateTime']
    quantity: Scalars['Long']
    fromState?: Maybe<State>
    toState?: Maybe<State>
    fromStateRef: Reference
    toStateRef: Reference
    type: Scalars['String']
  }

export type LineItemsTarget = CartDiscountTarget & {
  predicate: Scalars['String']
  type: Scalars['String']
}

export type LocalizableEnumAttributeDefinitionType = AttributeDefinitionType & {
  values: LocalizableEnumValueTypeResult
  name: Scalars['String']
}

export type LocalizableEnumAttributeDefinitionTypeValuesArgs = {
  includeKeys?: InputMaybe<Array<Scalars['String']>>
  excludeKeys?: InputMaybe<Array<Scalars['String']>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  sort?: InputMaybe<Array<Scalars['String']>>
}

export type LocalizableEnumValueType = {
  key: Scalars['String']
  label?: Maybe<Scalars['String']>
  labelAllLocales: Array<LocalizedString>
}

export type LocalizableEnumValueTypeLabelArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type LocalizableEnumValueTypeResult = {
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  total: Scalars['Int']
  results: Array<LocalizableEnumValueType>
}

export type LocalizableTextAttributeDefinitionType = AttributeDefinitionType & {
  name: Scalars['String']
}

export type LocalizedEnumType = FieldType & {
  values: Array<LocalizedEnumValue>
  name: Scalars['String']
}

export type LocalizedEnumValue = {
  key: Scalars['String']
  label?: Maybe<Scalars['String']>
  labelAllLocales: Array<LocalizedString>
}

export type LocalizedEnumValueLabelArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type LocalizedString = {
  locale: Scalars['Locale']
  value: Scalars['String']
}

export type LocalizedStringType = FieldType & {
  name: Scalars['String']
}

export type Location = {
  country: Country
  state?: Maybe<Scalars['String']>
}

export enum MeasurementSystem {
  Imperial = 'IMPERIAL',
  Metric = 'METRIC',
}

export type Merchandise = {
  type: Scalars['String']
  home_good_item?: Maybe<HomeGoodItem>
}

export type Message = Versioned &
  ReferenceExpandable & {
    id: Scalars['String']
    type: Scalars['String']
    sequenceNumber: Scalars['Long']
    resourceRef: Reference
    resourceVersion: Scalars['Long']
    userProvidedIdentifiers?: Maybe<UserProvidedIdentifiers>
    payload: MessagePayload
    version: Scalars['Long']
    createdAt: Scalars['DateTime']
    lastModifiedAt: Scalars['DateTime']
    createdBy?: Maybe<Initiator>
    lastModifiedBy?: Maybe<Initiator>
  }

export type MessageId = {
  id: Scalars['String']
  sequenceNumber: Scalars['Long']
}

export type MessagePayload = {
  type: Scalars['String']
}

export type MethodTaxedPrice = {
  shippingMethodKey: Scalars['String']
  taxedPrice?: Maybe<TaxedItemPrice>
}

export type Money = BaseMoney & {
  type: Scalars['String']
  currencyCode: Scalars['Currency']
  centAmount: Scalars['Long']
  /** For the `Money` it equals to the default number of fraction digits used with the currency. */
  fractionDigits: Scalars['Int']
}

export type MoneyAttributeDefinitionType = AttributeDefinitionType & {
  name: Scalars['String']
}

export type MoneyType = FieldType & {
  name: Scalars['String']
}

export type MultiBuyCustomLineItemsTarget = CartDiscountTarget & {
  predicate: Scalars['String']
  triggerQuantity: Scalars['Long']
  discountedQuantity: Scalars['Long']
  maxOccurrence?: Maybe<Scalars['Int']>
  selectionMode: SelectionMode
  type: Scalars['String']
}

export type MultiBuyLineItemsTarget = CartDiscountTarget & {
  predicate: Scalars['String']
  triggerQuantity: Scalars['Long']
  discountedQuantity: Scalars['Long']
  maxOccurrence?: Maybe<Scalars['Int']>
  selectionMode: SelectionMode
  type: Scalars['String']
}

export type Mutation = {
  addToCart: AddToCartReturn
  addToCommercetoolsCart: Cart
  createAnonymousUser?: Maybe<User>
  deleteSavedItem?: Maybe<Scalars['Boolean']>
  moveFromSavedToCart: AddToCartReturn
  updateItemQuantity: AddToCartReturn
  redeemSpoondollars?: Maybe<Scalars['Boolean']>
  saveForLater: AddToCartReturn
  updatePreferences?: Maybe<User>
}

export type MutationAddToCartArgs = {
  cartItem: AddToCartItem
}

export type MutationAddToCommercetoolsCartArgs = {
  cartItem: AddToCartItem
}

export type MutationCreateAnonymousUserArgs = {
  preferences: PreferencesInput
}

export type MutationDeleteSavedItemArgs = {
  itemId: Scalars['Int']
}

export type MutationMoveFromSavedToCartArgs = {
  cartItem: CartPatchItem
}

export type MutationUpdateItemQuantityArgs = {
  patchItem: CartPatchItem
}

export type MutationSaveForLaterArgs = {
  cartItem: AddToCartItem
}

export type MutationUpdatePreferencesArgs = {
  preferences: PreferencesInput
}

export type NestedAttributeDefinitionType = AttributeDefinitionType & {
  typeRef: Reference
  name: Scalars['String']
}

export type NotProcessed = OrderEditResult & {
  type: Scalars['String']
}

export type NumberAttributeDefinitionType = AttributeDefinitionType & {
  name: Scalars['String']
}

export type NumberType = FieldType & {
  name: Scalars['String']
}

/**
 * An order can be created from a cart, usually after a checkout process has been completed.
 * [documentation](https://docs.commercetools.com/api/projects/orders)
 */
export type Order = Versioned &
  ReferenceExpandable & {
    customerId?: Maybe<Scalars['String']>
    customer?: Maybe<Customer>
    customerEmail?: Maybe<Scalars['String']>
    anonymousId?: Maybe<Scalars['String']>
    lineItems: Array<LineItem>
    customLineItems: Array<CustomLineItem>
    totalPrice: Money
    taxedPrice?: Maybe<TaxedPrice>
    shippingAddress?: Maybe<Address>
    billingAddress?: Maybe<Address>
    inventoryMode: InventoryMode
    taxMode: TaxMode
    taxRoundingMode: RoundingMode
    taxCalculationMode: TaxCalculationMode
    customerGroup?: Maybe<CustomerGroup>
    customerGroupRef?: Maybe<Reference>
    country?: Maybe<Country>
    shippingInfo?: Maybe<ShippingInfo>
    discountCodes: Array<DiscountCodeInfo>
    /** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
    directDiscounts: Array<DirectDiscount>
    refusedGifts: Array<CartDiscount>
    refusedGiftsRefs: Array<Reference>
    paymentInfo?: Maybe<PaymentInfo>
    locale?: Maybe<Scalars['Locale']>
    shippingRateInput?: Maybe<ShippingRateInput>
    origin: CartOrigin
    storeRef?: Maybe<KeyReference>
    store?: Maybe<Store>
    itemShippingAddresses: Array<Address>
    businessUnit?: Maybe<KeyReference>
    completedAt?: Maybe<Scalars['DateTime']>
    orderNumber?: Maybe<Scalars['String']>
    orderState: OrderState
    stateRef?: Maybe<Reference>
    state?: Maybe<State>
    shipmentState?: Maybe<ShipmentState>
    paymentState?: Maybe<PaymentState>
    syncInfo: Array<SyncInfo>
    returnInfo: Array<ReturnInfo>
    /** @deprecated An internal field that should not be used in customer logic */
    lastMessageSequenceNumber: Scalars['Long']
    cartRef?: Maybe<Reference>
    cart?: Maybe<Cart>
    quoteRef?: Maybe<Reference>
    quote?: Maybe<Quote>
    custom?: Maybe<CustomFieldsType>
    id: Scalars['String']
    version: Scalars['Long']
    createdAt: Scalars['DateTime']
    lastModifiedAt: Scalars['DateTime']
    createdBy?: Maybe<Initiator>
    lastModifiedBy?: Maybe<Initiator>
  }

/**
 * An order can be created from a cart, usually after a checkout process has been completed.
 * [documentation](https://docs.commercetools.com/api/projects/orders)
 */
export type OrderLineItemsArgs = {
  id?: InputMaybe<Scalars['String']>
}

export type OrderBillingAddressSet = MessagePayload &
  OrderMessagePayload & {
    address?: Maybe<Address>
    oldAddress?: Maybe<Address>
    type: Scalars['String']
  }

export type OrderCreated = MessagePayload &
  OrderMessagePayload & {
    order: Order
    type: Scalars['String']
  }

export type OrderCustomLineItemAdded = MessagePayload &
  OrderMessagePayload & {
    customLineItem: CustomLineItem
    type: Scalars['String']
  }

export type OrderCustomLineItemDiscountSet = MessagePayload &
  OrderMessagePayload & {
    customLineItemId: Scalars['String']
    discountedPricePerQuantity: Array<DiscountedLineItemPriceForQuantity>
    taxedPrice?: Maybe<TaxedItemPrice>
    type: Scalars['String']
  }

export type OrderCustomLineItemQuantityChanged = MessagePayload &
  OrderMessagePayload & {
    customLineItemId: Scalars['String']
    quantity: Scalars['Long']
    oldQuantity?: Maybe<Scalars['Long']>
    type: Scalars['String']
  }

export type OrderCustomLineItemRemoved = MessagePayload &
  OrderMessagePayload & {
    customLineItemId: Scalars['String']
    customLineItem?: Maybe<CustomLineItem>
    type: Scalars['String']
  }

export type OrderCustomerEmailSet = MessagePayload &
  OrderMessagePayload & {
    email?: Maybe<Scalars['String']>
    oldEmail?: Maybe<Scalars['String']>
    type: Scalars['String']
  }

export type OrderCustomerGroupSet = MessagePayload &
  OrderMessagePayload & {
    customerGroup?: Maybe<CustomerGroup>
    oldCustomerGroup?: Maybe<CustomerGroup>
    customerGroupRef?: Maybe<Reference>
    oldCustomerGroupRef?: Maybe<Reference>
    type: Scalars['String']
  }

export type OrderCustomerSet = MessagePayload &
  OrderMessagePayload & {
    customer?: Maybe<Customer>
    oldCustomer?: Maybe<Customer>
    customerGroup?: Maybe<CustomerGroup>
    oldCustomerGroup?: Maybe<CustomerGroup>
    customerRef?: Maybe<Reference>
    oldCustomerRef?: Maybe<Reference>
    customerGroupRef?: Maybe<Reference>
    oldCustomerGroupRef?: Maybe<Reference>
    type: Scalars['String']
  }

export type OrderDeleted = MessagePayload &
  OrderMessagePayload & {
    order?: Maybe<Order>
    type: Scalars['String']
  }

export type OrderDiscountCodeAdded = MessagePayload &
  OrderMessagePayload & {
    discountCode?: Maybe<DiscountCode>
    discountCodeRef: Reference
    type: Scalars['String']
  }

export type OrderDiscountCodeRemoved = MessagePayload &
  OrderMessagePayload & {
    discountCode?: Maybe<DiscountCode>
    discountCodeRef: Reference
    type: Scalars['String']
  }

export type OrderDiscountCodeStateSet = MessagePayload &
  OrderMessagePayload & {
    state: DiscountCodeState
    oldState?: Maybe<DiscountCodeState>
    discountCode?: Maybe<DiscountCode>
    discountCodeRef: Reference
    type: Scalars['String']
  }

export type OrderEdit = Versioned & {
  key?: Maybe<Scalars['String']>
  resourceRef: Reference
  resource?: Maybe<Order>
  stagedActions: Array<StagedOrderUpdateActionOutput>
  result: OrderEditResult
  comment?: Maybe<Scalars['String']>
  custom?: Maybe<CustomFieldsType>
  id: Scalars['String']
  version: Scalars['Long']
  createdAt: Scalars['DateTime']
  lastModifiedAt: Scalars['DateTime']
  createdBy?: Maybe<Initiator>
  lastModifiedBy?: Maybe<Initiator>
}

export type OrderEditApplied = MessagePayload &
  OrderMessagePayload & {
    result: Applied
    edit?: Maybe<OrderEdit>
    editRef: Reference
    type: Scalars['String']
  }

export type OrderEditResult = {
  type: Scalars['String']
}

export type OrderExcerpt = {
  totalPrice: Money
  taxedPrice?: Maybe<TaxedPrice>
  version?: Maybe<Scalars['Long']>
}

export type OrderImported = MessagePayload &
  OrderMessagePayload & {
    order: Order
    type: Scalars['String']
  }

export type OrderLineItem = {
  id: Scalars['Int']
  order_id?: Maybe<Scalars['Int']>
  saved?: Maybe<Scalars['Boolean']>
  quantity?: Maybe<Quantity>
  url?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  favorite?: Maybe<Favorite>
  fabric?: Maybe<Fabric>
  design?: Maybe<Design>
  pricing?: Maybe<Pricing>
  analytics?: Maybe<Analytics>
  turnarounds?: Maybe<Turnarounds>
  merchandise?: Maybe<Merchandise>
}

export type OrderLineItemAdded = MessagePayload &
  OrderMessagePayload & {
    lineItem: LineItem
    addedQuantity: Scalars['Long']
    type: Scalars['String']
  }

export type OrderLineItemDiscountSet = MessagePayload &
  OrderMessagePayload & {
    lineItemId: Scalars['String']
    discountedPricePerQuantity: Array<DiscountedLineItemPriceForQuantity>
    totalPrice: Money
    taxedPrice?: Maybe<TaxedItemPrice>
    taxedPricePortions: Array<MethodTaxedPrice>
    type: Scalars['String']
  }

export type OrderLineItemDistributionChannelSet = MessagePayload &
  OrderMessagePayload & {
    lineItemId: Scalars['String']
    distributionChannel?: Maybe<Channel>
    distributionChannelRef?: Maybe<Reference>
    type: Scalars['String']
  }

export type OrderLineItemRemoved = MessagePayload &
  OrderMessagePayload & {
    lineItemId: Scalars['String']
    removedQuantity: Scalars['Long']
    newQuantity: Scalars['Long']
    newState: Scalars['Set']
    newTotalPrice: Money
    newTaxedPrice?: Maybe<TaxedItemPrice>
    newPrice?: Maybe<ProductPrice>
    newShippingDetails?: Maybe<ItemShippingDetails>
    type: Scalars['String']
  }

export type OrderMessagePayload = {
  type: Scalars['String']
}

export type OrderPaymentAdded = MessagePayload &
  OrderMessagePayload & {
    paymentRef: Reference
    type: Scalars['String']
  }

export type OrderPaymentRemoved = MessagePayload &
  OrderMessagePayload & {
    paymentRef: Reference
    removedPaymentInfo: Scalars['Boolean']
    type: Scalars['String']
  }

export type OrderPaymentStateChanged = MessagePayload &
  OrderMessagePayload & {
    paymentState: PaymentState
    oldPaymentState?: Maybe<PaymentState>
    type: Scalars['String']
  }

export type OrderReturnShipmentStateChanged = MessagePayload &
  OrderMessagePayload & {
    returnItemId: Scalars['String']
    returnShipmentState: ReturnShipmentState
    type: Scalars['String']
  }

export type OrderShipmentStateChanged = MessagePayload &
  OrderMessagePayload & {
    shipmentState: ShipmentState
    oldShipmentState?: Maybe<ShipmentState>
    type: Scalars['String']
  }

export type OrderShippingAddressSet = MessagePayload &
  OrderMessagePayload & {
    address?: Maybe<Address>
    oldAddress?: Maybe<Address>
    type: Scalars['String']
  }

export type OrderShippingInfoSet = MessagePayload &
  OrderMessagePayload & {
    shippingInfo?: Maybe<ShippingInfo>
    oldShippingInfo?: Maybe<ShippingInfo>
    type: Scalars['String']
  }

export type OrderShippingRateInputSet = MessagePayload &
  OrderMessagePayload & {
    shippingRateInput?: Maybe<ShippingRateInput>
    oldShippingRateInput?: Maybe<ShippingRateInput>
    type: Scalars['String']
  }

export enum OrderState {
  Confirmed = 'Confirmed',
  Cancelled = 'Cancelled',
  Complete = 'Complete',
  Open = 'Open',
}

export type OrderStateChanged = MessagePayload &
  OrderMessagePayload & {
    orderId: Scalars['String']
    orderState: OrderState
    oldOrderState?: Maybe<OrderState>
    type: Scalars['String']
  }

export type OrderStateTransition = MessagePayload &
  OrderMessagePayload & {
    force: Scalars['Boolean']
    state?: Maybe<State>
    oldState?: Maybe<State>
    stateRef: Reference
    oldStateRef?: Maybe<Reference>
    type: Scalars['String']
  }

export type OrderStoreSet = MessagePayload &
  OrderMessagePayload & {
    store?: Maybe<Store>
    oldStore?: Maybe<Store>
    storeRef?: Maybe<KeyReference>
    oldStoreRef?: Maybe<KeyReference>
    type: Scalars['String']
  }

export type Parcel = {
  id: Scalars['String']
  createdAt: Scalars['DateTime']
  measurements?: Maybe<ParcelMeasurements>
  trackingData?: Maybe<TrackingData>
  items: Array<DeliveryItem>
  custom?: Maybe<CustomFieldsType>
}

export type ParcelAddedToDelivery = MessagePayload &
  OrderMessagePayload & {
    delivery: Delivery
    parcel: Parcel
    shippingKey?: Maybe<Scalars['String']>
    type: Scalars['String']
  }

export type ParcelData = {
  measurements?: Maybe<ParcelMeasurements>
  trackingData?: Maybe<TrackingData>
  items: Array<DeliveryItem>
  custom?: Maybe<CustomFieldsCommand>
}

export type ParcelItemsUpdated = MessagePayload &
  OrderMessagePayload & {
    deliveryId: Scalars['String']
    parcelId: Scalars['String']
    items: Array<DeliveryItem>
    oldItems: Array<DeliveryItem>
    shippingKey?: Maybe<Scalars['String']>
    type: Scalars['String']
  }

export type ParcelMeasurements = {
  heightInMillimeter?: Maybe<Scalars['Int']>
  lengthInMillimeter?: Maybe<Scalars['Int']>
  widthInMillimeter?: Maybe<Scalars['Int']>
  weightInGram?: Maybe<Scalars['Int']>
}

export type ParcelMeasurementsUpdated = MessagePayload &
  OrderMessagePayload & {
    deliveryId: Scalars['String']
    parcelId: Scalars['String']
    measurements?: Maybe<ParcelMeasurements>
    shippingKey?: Maybe<Scalars['String']>
    type: Scalars['String']
  }

export type ParcelRemovedFromDelivery = MessagePayload &
  OrderMessagePayload & {
    deliveryId: Scalars['String']
    parcel: Parcel
    shippingKey?: Maybe<Scalars['String']>
    type: Scalars['String']
  }

export type ParcelTrackingDataUpdated = MessagePayload &
  OrderMessagePayload & {
    deliveryId: Scalars['String']
    parcelId: Scalars['String']
    trackingData?: Maybe<TrackingData>
    shippingKey?: Maybe<Scalars['String']>
    type: Scalars['String']
  }

/**
 * Payments hold information about the current state of receiving and/or refunding money.
 * [documentation](https://docs.commercetools.com/api/projects/payments)
 */
export type Payment = Versioned & {
  key?: Maybe<Scalars['String']>
  customerRef?: Maybe<Reference>
  customer?: Maybe<Customer>
  anonymousId?: Maybe<Scalars['String']>
  interfaceId?: Maybe<Scalars['String']>
  amountPlanned: Money
  paymentMethodInfo: PaymentMethodInfo
  paymentStatus: PaymentStatus
  transactions: Array<Transaction>
  interfaceInteractionsRaw: InterfaceInteractionsRawResult
  custom?: Maybe<CustomFieldsType>
  id: Scalars['String']
  version: Scalars['Long']
  createdAt: Scalars['DateTime']
  lastModifiedAt: Scalars['DateTime']
  createdBy?: Maybe<Initiator>
  lastModifiedBy?: Maybe<Initiator>
}

/**
 * Payments hold information about the current state of receiving and/or refunding money.
 * [documentation](https://docs.commercetools.com/api/projects/payments)
 */
export type PaymentInterfaceInteractionsRawArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type PaymentCreated = MessagePayload & {
  payment: Payment
  type: Scalars['String']
}

export type PaymentInfo = {
  payments: Array<Payment>
  paymentRefs: Array<Reference>
}

export type PaymentInteractionAdded = MessagePayload & {
  interaction: CustomFieldsType
  type: Scalars['String']
}

export type PaymentMethodInfo = {
  paymentInterface?: Maybe<Scalars['String']>
  method?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  nameAllLocales?: Maybe<Array<LocalizedString>>
}

export type PaymentMethodInfoNameArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export enum PaymentState {
  Paid = 'Paid',
  CreditOwed = 'CreditOwed',
  Pending = 'Pending',
  Failed = 'Failed',
  BalanceDue = 'BalanceDue',
}

export type PaymentStatus = {
  interfaceCode?: Maybe<Scalars['String']>
  interfaceText?: Maybe<Scalars['String']>
  stateRef?: Maybe<Reference>
  state?: Maybe<State>
}

export type PaymentStatusInterfaceCodeSet = MessagePayload & {
  paymentId: Scalars['String']
  interfaceCode?: Maybe<Scalars['String']>
  type: Scalars['String']
}

export type PaymentStatusStateTransition = MessagePayload & {
  force: Scalars['Boolean']
  state?: Maybe<State>
  stateRef?: Maybe<Reference>
  type: Scalars['String']
}

export type PaymentTransactionAdded = MessagePayload & {
  transaction: Transaction
  type: Scalars['String']
}

export type PaymentTransactionStateChanged = MessagePayload & {
  transactionId: Scalars['String']
  state: TransactionState
  type: Scalars['String']
}

export type PlainEnumValue = {
  key: Scalars['String']
  label: Scalars['String']
}

export type PlainEnumValueResult = {
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  total: Scalars['Int']
  results: Array<PlainEnumValue>
}

export type Plus = {
  expirationDate?: Maybe<Scalars['Date']>
  isActive: Scalars['Boolean']
  isRenewable?: Maybe<Scalars['Boolean']>
}

export type Point = Geometry & {
  type: Scalars['String']
  coordinates: Array<Scalars['Float']>
}

export type Preferences = {
  currency?: Maybe<AlpCurrency>
  measurement_system?: Maybe<MeasurementSystem>
  country?: Maybe<Country>
  locale?: Maybe<Scalars['String']>
  adult_content?: Maybe<AdultContent>
}

export type PreferencesInput = {
  currency: AlpCurrency
  measurement_system: MeasurementSystem
  country: Country
  locale?: InputMaybe<Scalars['String']>
  adult_content?: InputMaybe<AdultContent>
}

export type PreviewFailure = OrderEditResult & {
  type: Scalars['String']
  errors: Array<Scalars['Json']>
}

export type PreviewSuccess = OrderEditResult & {
  type: Scalars['String']
  preview: Order
  messagePayloads: Array<OrderMessagePayload>
}

export type PriceFunction = {
  function: Scalars['String']
  currencyCode: Scalars['Currency']
}

/**
 * This mode determines which type of Prices the system uses for
 * Product Price Selection as well as for LineItem Price selection
 */
export enum PriceMode {
  /** The system looks up prices from Standalone Prices, stored separately from Products. */
  Standalone = 'Standalone',
  /** The system looks up prices from the `prices` field of the ProductVariant inside a Product. */
  Embedded = 'Embedded',
}

export type PricePer = {
  raw_unit?: Maybe<Scalars['Float']>
  raw_unit_undiscounted?: Maybe<Scalars['Float']>
  raw_unit_leader?: Maybe<Scalars['Float']>
  raw_unit_leader_undiscounted?: Maybe<Scalars['Float']>
  unit_type?: Maybe<MeasurementSystem>
}

export type Pricing = {
  tax_applicable?: Maybe<Scalars['Boolean']>
  vat_exempt?: Maybe<Scalars['Boolean']>
  raw_price?: Maybe<Scalars['Float']>
  raw_price_usd?: Maybe<Scalars['Float']>
  raw_price_undiscounted?: Maybe<Scalars['Float']>
  raw_price_undiscounted_usd?: Maybe<Scalars['Float']>
  price_per?: Maybe<PricePer>
  discounts_applied?: Maybe<Scalars['Boolean']>
  currency?: Maybe<AlpCurrency>
}

export type PrivateBuyer = {
  design_id: Scalars['Int']
  id: Scalars['Int']
  user_id: Scalars['Int']
}

export type Product = Versioned &
  ReviewTarget &
  ReferenceExpandable & {
    id: Scalars['String']
    key?: Maybe<Scalars['String']>
    version: Scalars['Long']
    productTypeRef: Reference
    productType?: Maybe<ProductTypeDefinition>
    masterData: ProductCatalogData
    skus: Array<Scalars['String']>
    createdAt: Scalars['DateTime']
    lastModifiedAt: Scalars['DateTime']
    stateRef?: Maybe<Reference>
    state?: Maybe<State>
    priceMode?: Maybe<PriceMode>
    taxCategoryRef?: Maybe<Reference>
    taxCategory?: Maybe<TaxCategory>
    reviewRatingStatistics?: Maybe<ReviewRatingStatistics>
    /** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
    productSelectionRefs: SelectionOfProductQueryResult
    createdBy?: Maybe<Initiator>
    lastModifiedBy?: Maybe<Initiator>
  }

export type ProductProductSelectionRefsArgs = {
  where?: InputMaybe<Scalars['String']>
  sort?: InputMaybe<Array<Scalars['String']>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type ProductAddedToCategory = MessagePayload & {
  category: ReferenceId
  staged: Scalars['Boolean']
  type: Scalars['String']
}

export type ProductCatalogData = {
  current?: Maybe<ProductData>
  staged?: Maybe<ProductData>
  published: Scalars['Boolean']
  hasStagedChanges: Scalars['Boolean']
}

export type ProductCreated = MessagePayload & {
  productProjection: ProductProjectionMessagePayload
  type: Scalars['String']
}

export type ProductData = {
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  nameAllLocales: Array<LocalizedString>
  descriptionAllLocales?: Maybe<Array<LocalizedString>>
  slug?: Maybe<Scalars['String']>
  slugAllLocales: Array<LocalizedString>
  categoryOrderHint?: Maybe<Scalars['String']>
  categoryOrderHints: Array<CategoryOrderHint>
  categoriesRef: Array<Reference>
  categories: Array<Category>
  searchKeyword?: Maybe<Array<SearchKeyword>>
  searchKeywords: Array<SearchKeywords>
  metaTitle?: Maybe<Scalars['String']>
  metaTitleAllLocales?: Maybe<Array<LocalizedString>>
  metaKeywords?: Maybe<Scalars['String']>
  metaKeywordsAllLocales?: Maybe<Array<LocalizedString>>
  metaDescription?: Maybe<Scalars['String']>
  metaDescriptionAllLocales?: Maybe<Array<LocalizedString>>
  masterVariant: ProductVariant
  variants: Array<ProductVariant>
  allVariants: Array<ProductVariant>
  variant?: Maybe<ProductVariant>
  skus: Array<Scalars['String']>
}

export type ProductDataNameArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type ProductDataDescriptionArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type ProductDataSlugArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type ProductDataCategoryOrderHintArgs = {
  categoryId: Scalars['String']
}

export type ProductDataSearchKeywordArgs = {
  locale: Scalars['Locale']
}

export type ProductDataMetaTitleArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type ProductDataMetaKeywordsArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type ProductDataMetaDescriptionArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type ProductDataVariantsArgs = {
  skus?: InputMaybe<Array<Scalars['String']>>
  isOnStock?: InputMaybe<Scalars['Boolean']>
  stockChannelIds?: InputMaybe<Array<Scalars['String']>>
  hasImages?: InputMaybe<Scalars['Boolean']>
}

export type ProductDataAllVariantsArgs = {
  skus?: InputMaybe<Array<Scalars['String']>>
  isOnStock?: InputMaybe<Scalars['Boolean']>
  stockChannelIds?: InputMaybe<Array<Scalars['String']>>
  hasImages?: InputMaybe<Scalars['Boolean']>
}

export type ProductDataVariantArgs = {
  sku?: InputMaybe<Scalars['String']>
  key?: InputMaybe<Scalars['String']>
}

export type ProductDeleted = MessagePayload & {
  removedImageUrls: Scalars['Set']
  currentProjection?: Maybe<ProductProjectionMessagePayload>
  type: Scalars['String']
}

/**
 * A product price can be discounted in two ways:
 * * with a relative or an absolute product discount, which will be automatically applied to all prices in a product that match a discount predicate.
 *   A relative discount reduces the matching price by a fraction (for example 10 % off). An absolute discount reduces the matching price by a fixed amount (for example 10€ off). If more than one product discount matches a price, the discount sort order determines which one will be applied.
 * * with an external product discount, which can then be used to explicitly set a discounted value on a particular product price.
 * The discounted price is stored in the discounted field of the Product Price.
 * Note that when a discount is created, updated or removed it can take up to 15 minutes to update all the prices with the discounts.
 * The maximum number of ProductDiscounts that can be active at the same time is **200**.
 */
export type ProductDiscount = Versioned &
  ReferenceExpandable & {
    predicate: Scalars['String']
    validFrom?: Maybe<Scalars['DateTime']>
    validUntil?: Maybe<Scalars['DateTime']>
    isActive: Scalars['Boolean']
    isValid: Scalars['Boolean']
    sortOrder: Scalars['String']
    key?: Maybe<Scalars['String']>
    name?: Maybe<Scalars['String']>
    description?: Maybe<Scalars['String']>
    referenceRefs: Array<Reference>
    nameAllLocales: Array<LocalizedString>
    descriptionAllLocales?: Maybe<Array<LocalizedString>>
    value: ProductDiscountValue
    id: Scalars['String']
    version: Scalars['Long']
    createdAt: Scalars['DateTime']
    lastModifiedAt: Scalars['DateTime']
    createdBy?: Maybe<Initiator>
    lastModifiedBy?: Maybe<Initiator>
  }

/**
 * A product price can be discounted in two ways:
 * * with a relative or an absolute product discount, which will be automatically applied to all prices in a product that match a discount predicate.
 *   A relative discount reduces the matching price by a fraction (for example 10 % off). An absolute discount reduces the matching price by a fixed amount (for example 10€ off). If more than one product discount matches a price, the discount sort order determines which one will be applied.
 * * with an external product discount, which can then be used to explicitly set a discounted value on a particular product price.
 * The discounted price is stored in the discounted field of the Product Price.
 * Note that when a discount is created, updated or removed it can take up to 15 minutes to update all the prices with the discounts.
 * The maximum number of ProductDiscounts that can be active at the same time is **200**.
 */
export type ProductDiscountNameArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

/**
 * A product price can be discounted in two ways:
 * * with a relative or an absolute product discount, which will be automatically applied to all prices in a product that match a discount predicate.
 *   A relative discount reduces the matching price by a fraction (for example 10 % off). An absolute discount reduces the matching price by a fixed amount (for example 10€ off). If more than one product discount matches a price, the discount sort order determines which one will be applied.
 * * with an external product discount, which can then be used to explicitly set a discounted value on a particular product price.
 * The discounted price is stored in the discounted field of the Product Price.
 * Note that when a discount is created, updated or removed it can take up to 15 minutes to update all the prices with the discounts.
 * The maximum number of ProductDiscounts that can be active at the same time is **200**.
 */
export type ProductDiscountDescriptionArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type ProductDiscountValue = {
  type: Scalars['String']
}

export type ProductImageAdded = MessagePayload & {
  variantId: Scalars['Int']
  image: Image
  staged: Scalars['Boolean']
  type: Scalars['String']
}

/** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
export type ProductOfSelection = {
  productRef: Reference
  product?: Maybe<Product>
  variantSelection?: Maybe<ProductVariantSelection>
}

export type ProductOfSelectionQueryResult = {
  offset: Scalars['Int']
  count: Scalars['Int']
  total: Scalars['Long']
  /** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
  exists: Scalars['Boolean']
  results: Array<ProductOfSelection>
}

export type ProductPrice = {
  id?: Maybe<Scalars['String']>
  value: BaseMoney
  key?: Maybe<Scalars['String']>
  country?: Maybe<Country>
  customerGroup?: Maybe<CustomerGroup>
  customerGroupRef?: Maybe<Reference>
  channel?: Maybe<Channel>
  channelRef?: Maybe<Reference>
  validFrom?: Maybe<Scalars['DateTime']>
  validUntil?: Maybe<Scalars['DateTime']>
  discounted?: Maybe<DiscountedProductPriceValue>
  tiers?: Maybe<Array<ProductPriceTier>>
  custom?: Maybe<CustomFieldsType>
}

export type ProductPriceAdded = MessagePayload & {
  variantId: Scalars['Int']
  price: ProductPrice
  staged: Scalars['Boolean']
  hasStagedChanges?: Maybe<Scalars['Boolean']>
  type: Scalars['String']
}

export type ProductPriceChanged = MessagePayload & {
  variantId: Scalars['Int']
  oldPrice: ProductPrice
  newPrice: ProductPrice
  staged: Scalars['Boolean']
  oldStagedPrice?: Maybe<ProductPrice>
  hasStagedChanges?: Maybe<Scalars['Boolean']>
  type: Scalars['String']
}

export type ProductPriceDiscountUpdateMessagePayload = {
  variantId: Scalars['Int']
  variantKey?: Maybe<Scalars['String']>
  sku?: Maybe<Scalars['String']>
  priceId: Scalars['String']
  discounted?: Maybe<DiscountedProductPriceValue>
  staged: Scalars['Boolean']
}

export type ProductPriceDiscountsSet = MessagePayload & {
  updatedPrices: Array<ProductPriceDiscountUpdateMessagePayload>
  type: Scalars['String']
}

export type ProductPriceExternalDiscountSet = MessagePayload & {
  variantId: Scalars['Int']
  variantKey?: Maybe<Scalars['String']>
  sku?: Maybe<Scalars['String']>
  priceId: Scalars['String']
  discounted?: Maybe<DiscountedProductPriceValue>
  staged: Scalars['Boolean']
  type: Scalars['String']
}

export type ProductPriceKeySet = MessagePayload & {
  variantId: Scalars['Int']
  priceId?: Maybe<Scalars['String']>
  oldKey?: Maybe<Scalars['String']>
  key?: Maybe<Scalars['String']>
  staged: Scalars['Boolean']
  hasStagedChanges?: Maybe<Scalars['Boolean']>
  type: Scalars['String']
}

export type ProductPriceModeSet = MessagePayload & {
  to?: Maybe<PriceMode>
  type: Scalars['String']
}

export type ProductPriceRemoved = MessagePayload & {
  variantId: Scalars['Int']
  price: ProductPrice
  staged: Scalars['Boolean']
  hasStagedChanges?: Maybe<Scalars['Boolean']>
  type: Scalars['String']
}

export type ProductPriceTier = {
  minimumQuantity: Scalars['Int']
  value: BaseMoney
}

export type ProductPricesSet = MessagePayload & {
  variantId: Scalars['Int']
  prices: Array<ProductPrice>
  staged: Scalars['Boolean']
  hasStagedChanges?: Maybe<Scalars['Boolean']>
  type: Scalars['String']
}

export type ProductProjectionMessagePayload = {
  id: Scalars['String']
  version: Scalars['Long']
  createdAt: Scalars['DateTime']
  lastModifiedAt: Scalars['DateTime']
  productTypeRef: Reference
  productType?: Maybe<ProductTypeDefinition>
  name?: Maybe<Scalars['String']>
  nameAllLocales: Array<LocalizedString>
  description?: Maybe<Scalars['String']>
  descriptionAllLocales?: Maybe<Array<LocalizedString>>
  slug?: Maybe<Scalars['String']>
  slugAllLocales: Array<LocalizedString>
  categoryOrderHints: Array<CategoryOrderHint>
  categoriesRef: Array<Reference>
  categories: Array<Category>
  searchKeywords: Array<SearchKeywords>
  metaTitle?: Maybe<Scalars['String']>
  metaTitleAllLocales?: Maybe<Array<LocalizedString>>
  metaKeywords?: Maybe<Scalars['String']>
  metaKeywordsAllLocales?: Maybe<Array<LocalizedString>>
  metaDescription?: Maybe<Scalars['String']>
  metaDescriptionAllLocales?: Maybe<Array<LocalizedString>>
  hasStagedChanges: Scalars['Boolean']
  published: Scalars['Boolean']
  masterVariant: ProductVariant
  variants: Array<ProductVariant>
  taxCategoryRef?: Maybe<Reference>
  taxCategory?: Maybe<TaxCategory>
  stateRef?: Maybe<Reference>
  state?: Maybe<State>
  reviewRatingStatistics?: Maybe<ReviewRatingStatistics>
}

export type ProductProjectionMessagePayloadNameArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type ProductProjectionMessagePayloadDescriptionArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type ProductProjectionMessagePayloadSlugArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type ProductProjectionMessagePayloadMetaTitleArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type ProductProjectionMessagePayloadMetaKeywordsArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type ProductProjectionMessagePayloadMetaDescriptionArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type ProductPublished = MessagePayload & {
  productProjection: ProductProjectionMessagePayload
  removedImageUrls: Array<Scalars['String']>
  scope: PublishScope
  type: Scalars['String']
}

export type ProductReferenceIdentifier = {
  typeId: Scalars['String']
  id?: Maybe<Scalars['String']>
  key?: Maybe<Scalars['String']>
}

export type ProductRemovedFromCategory = MessagePayload & {
  category: ReferenceId
  staged: Scalars['Boolean']
  type: Scalars['String']
}

export type ProductRevertedStagedChanges = MessagePayload & {
  removedImageUrls: Scalars['Set']
  type: Scalars['String']
}

/** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
export type ProductSelection = Versioned & {
  key?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  nameAllLocales: Array<LocalizedString>
  productRefs: ProductOfSelectionQueryResult
  productCount: Scalars['Int']
  custom?: Maybe<CustomFieldsType>
  id: Scalars['String']
  version: Scalars['Long']
  createdAt: Scalars['DateTime']
  lastModifiedAt: Scalars['DateTime']
  createdBy?: Maybe<Initiator>
  lastModifiedBy?: Maybe<Initiator>
}

/** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
export type ProductSelectionNameArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

/** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
export type ProductSelectionProductRefsArgs = {
  where?: InputMaybe<Scalars['String']>
  sort?: InputMaybe<Array<Scalars['String']>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

/** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
export type ProductSelectionCreated = MessagePayload & {
  productSelection: ProductSelectionCreatedPayload
  type: Scalars['String']
}

export type ProductSelectionCreatedPayload = {
  name?: Maybe<Scalars['String']>
  nameAllLocales: Array<LocalizedString>
  custom?: Maybe<CustomFieldsType>
  type: Scalars['String']
}

export type ProductSelectionCreatedPayloadNameArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

/** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
export type ProductSelectionDeleted = MessagePayload & {
  type: Scalars['String']
}

/** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
export type ProductSelectionProductAdded = MessagePayload & {
  variantSelection?: Maybe<ProductVariantSelection>
  product?: Maybe<Product>
  productRef: Reference
  type: Scalars['String']
}

/** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
export type ProductSelectionProductRemoved = MessagePayload & {
  product?: Maybe<Product>
  productRef: Reference
  type: Scalars['String']
}

/** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
export type ProductSelectionSetting = {
  productSelectionRef: Reference
  productSelection?: Maybe<ProductSelection>
  active: Scalars['Boolean']
}

/** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
export type ProductSelectionVariantSelectionChanged = MessagePayload & {
  oldVariantSelection?: Maybe<ProductVariantSelection>
  newVariantSelection?: Maybe<ProductVariantSelection>
  product?: Maybe<Product>
  productRef: Reference
  type: Scalars['String']
}

export type ProductSlugChanged = MessagePayload & {
  slug?: Maybe<Scalars['String']>
  oldSlug?: Maybe<Scalars['String']>
  slugAllLocales: Array<LocalizedString>
  oldSlugAllLocales?: Maybe<Array<LocalizedString>>
  type: Scalars['String']
}

export type ProductSlugChangedSlugArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type ProductSlugChangedOldSlugArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type ProductStateTransition = MessagePayload & {
  force: Scalars['Boolean']
  state?: Maybe<State>
  stateRef: Reference
  type: Scalars['String']
}

export type ProductTurnTimes = {
  sku: Scalars['String']
  turn_times: TurnTimes
}

export type ProductTurnTimesResponse = {
  summary: TurnTimes
  product_turn_times: Array<ProductTurnTimes>
}

export type ProductTypeDefinition = Versioned &
  ReferenceExpandable & {
    key?: Maybe<Scalars['String']>
    name: Scalars['String']
    description: Scalars['String']
    attributeDefinitions: AttributeDefinitionResult
    id: Scalars['String']
    version: Scalars['Long']
    createdAt: Scalars['DateTime']
    lastModifiedAt: Scalars['DateTime']
    createdBy?: Maybe<Initiator>
    lastModifiedBy?: Maybe<Initiator>
  }

export type ProductTypeDefinitionAttributeDefinitionsArgs = {
  includeNames?: InputMaybe<Array<Scalars['String']>>
  excludeNames?: InputMaybe<Array<Scalars['String']>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  sort?: InputMaybe<Array<Scalars['String']>>
}

export type ProductUnpublished = MessagePayload & {
  type: Scalars['String']
}

export type ProductVariant = {
  id: Scalars['Int']
  key?: Maybe<Scalars['String']>
  sku?: Maybe<Scalars['String']>
  prices?: Maybe<Array<ProductPrice>>
  /** Returns a single price based on the price selection rules. */
  price?: Maybe<ProductPrice>
  images: Array<Image>
  assets: Array<Asset>
  availability?: Maybe<ProductVariantAvailabilityWithChannels>
  /** This field contains raw attributes data */
  attributesRaw: Array<RawProductAttribute>
}

export type ProductVariantPriceArgs = {
  currency: Scalars['Currency']
  country?: InputMaybe<Country>
  customerGroupId?: InputMaybe<Scalars['String']>
  channelId?: InputMaybe<Scalars['String']>
  date?: InputMaybe<Scalars['DateTime']>
}

export type ProductVariantAttributesRawArgs = {
  includeNames?: InputMaybe<Array<Scalars['String']>>
  excludeNames?: InputMaybe<Array<Scalars['String']>>
}

export type ProductVariantAdded = MessagePayload & {
  variant: ProductVariant
  staged: Scalars['Boolean']
  type: Scalars['String']
}

/** Product variant availabilities */
export type ProductVariantAvailabilitiesResult = {
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  total: Scalars['Int']
  results: Array<ProductVariantAvailabilityWithChannel>
}

/** Product variant availability */
export type ProductVariantAvailability = {
  isOnStock: Scalars['Boolean']
  restockableInDays?: Maybe<Scalars['Int']>
  availableQuantity?: Maybe<Scalars['Long']>
  version?: Maybe<Scalars['Long']>
  id?: Maybe<Scalars['String']>
}

export type ProductVariantAvailabilityWithChannel = {
  channelRef: Reference
  channel?: Maybe<Channel>
  availability: ProductVariantAvailability
}

export type ProductVariantAvailabilityWithChannels = {
  noChannel?: Maybe<ProductVariantAvailability>
  channels: ProductVariantAvailabilitiesResult
}

export type ProductVariantAvailabilityWithChannelsChannelsArgs = {
  includeChannelIds?: InputMaybe<Array<Scalars['String']>>
  excludeChannelIds?: InputMaybe<Array<Scalars['String']>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type ProductVariantDeleted = MessagePayload & {
  removedImageUrls: Scalars['Set']
  variant?: Maybe<ProductVariant>
  staged?: Maybe<Scalars['Boolean']>
  type: Scalars['String']
}

/** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
export type ProductVariantSelection = {
  type: Scalars['String']
  skus: Array<Scalars['String']>
}

/** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
export type ProductVariantSelectionExclusion = ProductVariantSelection & {
  type: Scalars['String']
  skus: Array<Scalars['String']>
}

/** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
export type ProductVariantSelectionInclusion = ProductVariantSelection & {
  type: Scalars['String']
  skus: Array<Scalars['String']>
}

export enum PublishScope {
  /** Publishes the complete staged projection */
  All = 'All',
  /** Publishes only prices on the staged projection */
  Prices = 'Prices',
}

export type Quantity = {
  value: Scalars['Int']
  changeable?: Maybe<Scalars['Boolean']>
}

export type Query = {
  user?: Maybe<User>
  userCT?: Maybe<Customer>
  legacyCart?: Maybe<LegacyCart>
  cartCount?: Maybe<CartCount>
  turnarounds?: Maybe<TurnTimes>
  myTurnarounds?: Maybe<ProductTurnTimesResponse>
  designSalabilityRequirements?: Maybe<Array<DesignSalabilityRequirements>>
  privateBuyer?: Maybe<PrivateBuyer>
  sourceDesigns?: Maybe<Array<SourceDesign>>
}

export type QueryLegacyCartArgs = {
  cartId: Scalars['Int']
}

export type QueryTurnaroundsArgs = {
  sku: Scalars['String']
}

export type QueryMyTurnaroundsArgs = {
  country?: InputMaybe<Scalars['String']>
}

export type QueryDesignSalabilityRequirementsArgs = {
  designIDs: Array<Scalars['Int']>
}

export type QueryPrivateBuyerArgs = {
  userID: Scalars['Int']
  designID: Scalars['Int']
}

export type QuerySourceDesignsArgs = {
  designID: Scalars['Int']
}

export type Quote = Versioned & {
  customerRef?: Maybe<Reference>
  customer?: Maybe<Customer>
  lineItems: Array<LineItem>
  customLineItems: Array<CustomLineItem>
  totalPrice: Money
  taxedPrice?: Maybe<TaxedPrice>
  shippingAddress?: Maybe<Address>
  billingAddress?: Maybe<Address>
  itemShippingAddresses: Array<Address>
  inventoryMode: InventoryMode
  taxMode: TaxMode
  taxRoundingMode: RoundingMode
  taxCalculationMode: TaxCalculationMode
  country?: Maybe<Country>
  shippingInfo?: Maybe<ShippingInfo>
  paymentInfo?: Maybe<PaymentInfo>
  /** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
  directDiscounts: Array<DirectDiscount>
  shippingRateInput?: Maybe<ShippingRateInput>
  storeRef?: Maybe<KeyReference>
  store?: Maybe<Store>
  businessUnit?: Maybe<KeyReference>
  key?: Maybe<Scalars['String']>
  quoteState: QuoteState
  stagedQuoteRef: Reference
  stagedQuote?: Maybe<StagedQuote>
  quoteRequestRef: Reference
  quoteRequest?: Maybe<QuoteRequest>
  validTo?: Maybe<Scalars['DateTime']>
  sellerComment?: Maybe<Scalars['String']>
  buyerComment?: Maybe<Scalars['String']>
  custom?: Maybe<CustomFieldsType>
  stateRef?: Maybe<Reference>
  state?: Maybe<State>
  id: Scalars['String']
  version: Scalars['Long']
  createdAt: Scalars['DateTime']
  lastModifiedAt: Scalars['DateTime']
  createdBy?: Maybe<Initiator>
  lastModifiedBy?: Maybe<Initiator>
}

export type QuoteLineItemsArgs = {
  id?: InputMaybe<Scalars['String']>
}

export type QuoteRequest = Versioned & {
  customerRef?: Maybe<Reference>
  customer?: Maybe<Customer>
  lineItems: Array<LineItem>
  customLineItems: Array<CustomLineItem>
  totalPrice: Money
  taxedPrice?: Maybe<TaxedPrice>
  shippingAddress?: Maybe<Address>
  billingAddress?: Maybe<Address>
  itemShippingAddresses: Array<Address>
  inventoryMode: InventoryMode
  taxMode: TaxMode
  taxRoundingMode: RoundingMode
  taxCalculationMode: TaxCalculationMode
  country?: Maybe<Country>
  shippingInfo?: Maybe<ShippingInfo>
  paymentInfo?: Maybe<PaymentInfo>
  /** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
  directDiscounts: Array<DirectDiscount>
  shippingRateInput?: Maybe<ShippingRateInput>
  storeRef?: Maybe<KeyReference>
  store?: Maybe<Store>
  businessUnit?: Maybe<KeyReference>
  key?: Maybe<Scalars['String']>
  quoteRequestState: QuoteRequestState
  comment?: Maybe<Scalars['String']>
  custom?: Maybe<CustomFieldsType>
  stateRef?: Maybe<Reference>
  state?: Maybe<State>
  id: Scalars['String']
  version: Scalars['Long']
  createdAt: Scalars['DateTime']
  lastModifiedAt: Scalars['DateTime']
  createdBy?: Maybe<Initiator>
  lastModifiedBy?: Maybe<Initiator>
}

export type QuoteRequestLineItemsArgs = {
  id?: InputMaybe<Scalars['String']>
}

export enum QuoteRequestState {
  Cancelled = 'Cancelled',
  Closed = 'Closed',
  Accepted = 'Accepted',
  UnderReview = 'UnderReview',
  Submitted = 'Submitted',
  Rejected = 'Rejected',
}

export enum QuoteState {
  Withdrawn = 'Withdrawn',
  Pending = 'Pending',
  Failed = 'Failed',
  DeclinedForRenegotiation = 'DeclinedForRenegotiation',
  Accepted = 'Accepted',
  Declined = 'Declined',
}

export type RawCustomField = {
  name: Scalars['String']
  value: Scalars['Json']
  /** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
  referencedResource?: Maybe<ReferenceExpandable>
  /** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
  referencedResourceSet: Array<ReferenceExpandable>
}

export type RawProductAttribute = {
  name: Scalars['String']
  value: Scalars['Json']
  attributeDefinition?: Maybe<AttributeDefinition>
  /** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
  referencedResource?: Maybe<ReferenceExpandable>
  /** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
  referencedResourceSet: Array<ReferenceExpandable>
}

export type RecalculateStagedOrderOutput = StagedOrderUpdateActionOutput & {
  type: Scalars['String']
  updateProductData: Scalars['Boolean']
}

export type Reference = {
  typeId: Scalars['String']
  id: Scalars['String']
}

export type ReferenceAttributeDefinitionType = AttributeDefinitionType & {
  referenceTypeId: Scalars['String']
  name: Scalars['String']
}

export type ReferenceExpandable = {
  id: Scalars['String']
}

export type ReferenceId = {
  typeId: Scalars['String']
  id: Scalars['String']
}

export type ReferenceType = FieldType & {
  referenceTypeId: Scalars['String']
  name: Scalars['String']
}

export type RelativeDiscountValue = CartDiscountValue &
  ProductDiscountValue & {
    permyriad: Scalars['Int']
    type: Scalars['String']
  }

export type RemoveStagedOrderCustomLineItemOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    customLineItemId: Scalars['String']
  }

export type RemoveStagedOrderDeliveryOutput = StagedOrderUpdateActionOutput & {
  type: Scalars['String']
  deliveryId: Scalars['String']
}

export type RemoveStagedOrderDiscountCodeOutput =
  StagedOrderUpdateActionOutput & {
    discountCode?: Maybe<DiscountCode>
    discountCodeRef: Reference
    type: Scalars['String']
  }

export type RemoveStagedOrderItemShippingAddressOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    addressKey: Scalars['String']
  }

export type RemoveStagedOrderLineItemOutput = StagedOrderUpdateActionOutput & {
  type: Scalars['String']
  lineItemId: Scalars['String']
  quantity?: Maybe<Scalars['Long']>
  externalPrice?: Maybe<BaseMoney>
  externalTotalPrice?: Maybe<ExternalLineItemTotalPrice>
  shippingDetailsToRemove?: Maybe<ItemShippingDetailsDraftOutput>
}

export type RemoveStagedOrderParcelFromDeliveryOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    parcelId: Scalars['String']
  }

export type RemoveStagedOrderPaymentOutput = StagedOrderUpdateActionOutput & {
  type: Scalars['String']
  paymentResId: ResourceIdentifier
}

export type ResourceIdentifier = {
  typeId: Scalars['String']
  key?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
}

/** Stores information about returns connected to this order. */
export type ReturnInfo = {
  items: Array<ReturnItem>
  returnTrackingId?: Maybe<Scalars['String']>
  returnDate?: Maybe<Scalars['DateTime']>
}

export type ReturnInfoAdded = MessagePayload &
  OrderMessagePayload & {
    returnInfo: ReturnInfo
    type: Scalars['String']
  }

export type ReturnInfoDraftTypeOutput = {
  items: Array<ReturnItemDraftTypeOutput>
  returnDate?: Maybe<Scalars['DateTime']>
  returnTrackingId?: Maybe<Scalars['String']>
}

export type ReturnInfoSet = MessagePayload &
  OrderMessagePayload & {
    returnInfo?: Maybe<Array<ReturnInfo>>
    type: Scalars['String']
  }

export type ReturnItem = {
  type: Scalars['String']
  id: Scalars['String']
  quantity: Scalars['Long']
  comment?: Maybe<Scalars['String']>
  shipmentState: ReturnShipmentState
  paymentState: ReturnPaymentState
  custom?: Maybe<CustomFieldsType>
  lastModifiedAt: Scalars['DateTime']
  createdAt: Scalars['DateTime']
}

export type ReturnItemDraftTypeOutput = {
  quantity: Scalars['Long']
  lineItemId?: Maybe<Scalars['String']>
  customLineItemId?: Maybe<Scalars['String']>
  comment?: Maybe<Scalars['String']>
  shipmentState: ReturnShipmentState
  custom?: Maybe<CustomFieldsCommand>
}

export enum ReturnPaymentState {
  NotRefunded = 'NotRefunded',
  Refunded = 'Refunded',
  Initial = 'Initial',
  NonRefundable = 'NonRefundable',
}

export enum ReturnShipmentState {
  Unusable = 'Unusable',
  BackInStock = 'BackInStock',
  Returned = 'Returned',
  Advised = 'Advised',
}

export type Review = Versioned &
  ReferenceExpandable & {
    key?: Maybe<Scalars['String']>
    uniquenessValue?: Maybe<Scalars['String']>
    locale?: Maybe<Scalars['Locale']>
    authorName?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
    text?: Maybe<Scalars['String']>
    targetRef?: Maybe<Reference>
    target?: Maybe<ReviewTarget>
    rating?: Maybe<Scalars['Int']>
    stateRef?: Maybe<Reference>
    state?: Maybe<State>
    includedInStatistics: Scalars['Boolean']
    customerRef?: Maybe<Reference>
    customer?: Maybe<Customer>
    custom?: Maybe<CustomFieldsType>
    id: Scalars['String']
    version: Scalars['Long']
    createdAt: Scalars['DateTime']
    lastModifiedAt: Scalars['DateTime']
    createdBy?: Maybe<Initiator>
    lastModifiedBy?: Maybe<Initiator>
  }

export type ReviewCreated = MessagePayload & {
  review: Review
  type: Scalars['String']
}

export type ReviewRatingSet = MessagePayload & {
  oldRating?: Maybe<Scalars['Int']>
  newRating?: Maybe<Scalars['Int']>
  includedInStatistics: Scalars['Boolean']
  target?: Maybe<ReviewTarget>
  targetRef?: Maybe<Reference>
  type: Scalars['String']
}

export type ReviewRatingStatistics = {
  averageRating: Scalars['Float']
  highestRating: Scalars['Int']
  lowestRating: Scalars['Int']
  count: Scalars['Long']
  ratingsDistribution: Scalars['Json']
}

export type ReviewStateTransition = MessagePayload & {
  oldIncludedInStatistics: Scalars['Boolean']
  newIncludedInStatistics: Scalars['Boolean']
  force: Scalars['Boolean']
  target?: Maybe<ReviewTarget>
  oldState?: Maybe<State>
  newState?: Maybe<State>
  targetRef?: Maybe<Reference>
  oldStateRef?: Maybe<Reference>
  newStateRef: Reference
  type: Scalars['String']
}

export type ReviewTarget = {
  id: Scalars['String']
}

export enum RoundingMode {
  /** [Round half down](https://en.wikipedia.org/wiki/Rounding#Round_half_down) */
  HalfDown = 'HalfDown',
  /** [Round half up](https://en.wikipedia.org/wiki/Rounding#Round_half_up) */
  HalfUp = 'HalfUp',
  /** [Round half to even](https://en.wikipedia.org/wiki/Rounding#Round_half_to_even). Default rounding mode as used in IEEE 754 computing functions and operators. */
  HalfEven = 'HalfEven',
}

export type ScoreShippingRateInput = ShippingRateInput & {
  score: Scalars['Int']
  type: Scalars['String']
}

export type ScoreShippingRateInputDraftOutput = ShippingRateInputDraftOutput & {
  score: Scalars['Int']
  type: Scalars['String']
}

export type SearchKeyword = {
  text: Scalars['String']
  suggestTokenizer?: Maybe<SuggestTokenizer>
}

export type SearchKeywords = {
  locale: Scalars['Locale']
  searchKeywords: Array<SearchKeyword>
}

/** In order to decide which of the matching items will actually be discounted */
export enum SelectionMode {
  MostExpensive = 'MostExpensive',
  Cheapest = 'Cheapest',
}

/** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
export type SelectionOfProduct = {
  productSelectionRef: Reference
  productSelection?: Maybe<ProductSelection>
  variantSelection?: Maybe<ProductVariantSelection>
  createdAt: Scalars['DateTime']
}

export type SelectionOfProductQueryResult = {
  offset: Scalars['Int']
  count: Scalars['Int']
  total: Scalars['Long']
  /** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
  exists: Scalars['Boolean']
  results: Array<SelectionOfProduct>
}

export type SetAttributeDefinitionType = AttributeDefinitionType & {
  elementType: AttributeDefinitionType
  name: Scalars['String']
}

export type SetStagedOrderBillingAddressCustomFieldOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    name: Scalars['String']
    value?: Maybe<Scalars['Json']>
  }

export type SetStagedOrderBillingAddressCustomTypeOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    custom: CustomFieldsCommand
  }

export type SetStagedOrderBillingAddressOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    address?: Maybe<AddressDraft>
  }

export type SetStagedOrderCountryOutput = StagedOrderUpdateActionOutput & {
  type: Scalars['String']
  country?: Maybe<Country>
}

export type SetStagedOrderCustomFieldOutput = StagedOrderUpdateActionOutput & {
  type: Scalars['String']
  name: Scalars['String']
  value?: Maybe<Scalars['Json']>
}

export type SetStagedOrderCustomLineItemCustomFieldOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    customLineItemId: Scalars['String']
    name: Scalars['String']
    value?: Maybe<Scalars['Json']>
  }

export type SetStagedOrderCustomLineItemCustomTypeOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    customLineItemId: Scalars['String']
    custom: CustomFieldsCommand
  }

export type SetStagedOrderCustomLineItemShippingDetailsOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    customLineItemId: Scalars['String']
    shippingDetails?: Maybe<ItemShippingDetailsDraftOutput>
  }

export type SetStagedOrderCustomLineItemTaxAmountOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    customLineItemId: Scalars['String']
    externalTaxAmount?: Maybe<ExternalTaxAmountDraftOutput>
  }

export type SetStagedOrderCustomLineItemTaxRateOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    customLineItemId: Scalars['String']
    externalTaxRate?: Maybe<ExternalTaxRateDraftOutput>
  }

export type SetStagedOrderCustomShippingMethodOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    shippingMethodName: Scalars['String']
    shippingRate: ShippingRate
    taxCategoryResId?: Maybe<ResourceIdentifier>
    externalTaxRate?: Maybe<ExternalTaxRateDraftOutput>
  }

export type SetStagedOrderCustomTypeOutput = StagedOrderUpdateActionOutput & {
  type: Scalars['String']
  custom: CustomFieldsCommand
}

export type SetStagedOrderCustomerEmailOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    email?: Maybe<Scalars['String']>
  }

export type SetStagedOrderCustomerGroupOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    customerGroupResId?: Maybe<CustomerGroupReferenceIdentifier>
  }

export type SetStagedOrderCustomerIdOutput = StagedOrderUpdateActionOutput & {
  type: Scalars['String']
  customerId?: Maybe<Scalars['String']>
}

export type SetStagedOrderDeliveryAddressCustomFieldOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    deliveryId: Scalars['String']
    name: Scalars['String']
    value?: Maybe<Scalars['Json']>
  }

export type SetStagedOrderDeliveryAddressCustomTypeOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    deliveryId: Scalars['String']
    custom: CustomFieldsCommand
  }

export type SetStagedOrderDeliveryAddressOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    deliveryId: Scalars['String']
    address?: Maybe<AddressDraft>
  }

export type SetStagedOrderDeliveryCustomFieldOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    name: Scalars['String']
    value?: Maybe<Scalars['Json']>
    deliveryId: Scalars['String']
  }

export type SetStagedOrderDeliveryCustomTypeOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    custom: CustomFieldsCommand
    deliveryId: Scalars['String']
  }

export type SetStagedOrderDeliveryItemsOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    deliveryId: Scalars['String']
    items: Array<DeliveryItem>
  }

export type SetStagedOrderDirectDiscountsOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    discounts: Array<DirectDiscountDraftOutput>
  }

export type SetStagedOrderItemShippingAddressCustomFieldOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    addressKey: Scalars['String']
    name: Scalars['String']
    value?: Maybe<Scalars['Json']>
  }

export type SetStagedOrderItemShippingAddressCustomTypeOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    addressKey: Scalars['String']
    custom: CustomFieldsCommand
  }

export type SetStagedOrderLineItemCustomFieldOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    lineItemId: Scalars['String']
    name: Scalars['String']
    value?: Maybe<Scalars['Json']>
  }

export type SetStagedOrderLineItemCustomTypeOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    lineItemId: Scalars['String']
    custom: CustomFieldsCommand
  }

export type SetStagedOrderLineItemDistributionChannelOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    lineItemId: Scalars['String']
    distributionChannelResId?: Maybe<ChannelReferenceIdentifier>
  }

export type SetStagedOrderLineItemPriceOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    lineItemId: Scalars['String']
    externalPrice?: Maybe<BaseMoney>
  }

export type SetStagedOrderLineItemShippingDetailsOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    lineItemId: Scalars['String']
    shippingDetails?: Maybe<ItemShippingDetailsDraftOutput>
  }

export type SetStagedOrderLineItemTaxAmountOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    lineItemId: Scalars['String']
    externalTaxAmount?: Maybe<ExternalTaxAmountDraftOutput>
    shippingKey?: Maybe<Scalars['String']>
  }

export type SetStagedOrderLineItemTaxRateOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    lineItemId: Scalars['String']
    externalTaxRate?: Maybe<ExternalTaxRateDraftOutput>
    shippingKey?: Maybe<Scalars['String']>
  }

export type SetStagedOrderLineItemTotalPriceOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    lineItemId: Scalars['String']
    externalTotalPrice?: Maybe<ExternalLineItemTotalPrice>
  }

export type SetStagedOrderLocaleOutput = StagedOrderUpdateActionOutput & {
  type: Scalars['String']
  locale?: Maybe<Scalars['Locale']>
}

export type SetStagedOrderOrderNumberOutput = StagedOrderUpdateActionOutput & {
  type: Scalars['String']
  orderNumber?: Maybe<Scalars['String']>
}

export type SetStagedOrderOrderTotalTaxOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    externalTotalGross?: Maybe<Money>
    externalTaxPortions: Array<TaxPortion>
  }

export type SetStagedOrderParcelCustomFieldOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    name: Scalars['String']
    value?: Maybe<Scalars['Json']>
    parcelId: Scalars['String']
  }

export type SetStagedOrderParcelCustomTypeOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    custom: CustomFieldsCommand
    parcelId: Scalars['String']
  }

export type SetStagedOrderParcelItemsOutput = StagedOrderUpdateActionOutput & {
  type: Scalars['String']
  parcelId: Scalars['String']
  items: Array<DeliveryItem>
}

export type SetStagedOrderParcelMeasurementsOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    parcelId: Scalars['String']
    measurements?: Maybe<ParcelMeasurements>
  }

export type SetStagedOrderParcelTrackingDataOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    parcelId: Scalars['String']
    trackingData?: Maybe<TrackingData>
  }

export type SetStagedOrderReturnInfoOutput = StagedOrderUpdateActionOutput & {
  type: Scalars['String']
  items: Array<ReturnInfoDraftTypeOutput>
}

export type SetStagedOrderReturnItemCustomFieldOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    name: Scalars['String']
    value?: Maybe<Scalars['Json']>
    returnItemId: Scalars['String']
  }

export type SetStagedOrderReturnItemCustomTypeOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    custom: CustomFieldsCommand
    returnItemId: Scalars['String']
  }

export type SetStagedOrderReturnPaymentStateOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    returnItemId: Scalars['String']
    paymentState: ReturnPaymentState
  }

export type SetStagedOrderReturnShipmentStateOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    returnItemId: Scalars['String']
    shipmentState: ReturnShipmentState
  }

export type SetStagedOrderShippingAddressAndCustomShippingMethodOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    address: AddressDraft
    shippingMethodName: Scalars['String']
    shippingRate: ShippingRate
    taxCategoryResId?: Maybe<ResourceIdentifier>
    externalTaxRate?: Maybe<ExternalTaxRateDraftOutput>
  }

export type SetStagedOrderShippingAddressAndShippingMethodOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    address: AddressDraft
    shippingMethodResId?: Maybe<ResourceIdentifier>
    externalTaxRate?: Maybe<ExternalTaxRateDraftOutput>
  }

export type SetStagedOrderShippingAddressCustomFieldOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    name: Scalars['String']
    value?: Maybe<Scalars['Json']>
  }

export type SetStagedOrderShippingAddressCustomTypeOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    custom: CustomFieldsCommand
  }

export type SetStagedOrderShippingAddressOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    address?: Maybe<AddressDraft>
  }

export type SetStagedOrderShippingCustomFieldOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    shippingKey?: Maybe<Scalars['String']>
    name: Scalars['String']
    value?: Maybe<Scalars['Json']>
  }

export type SetStagedOrderShippingCustomTypeOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    shippingKey?: Maybe<Scalars['String']>
    custom: CustomFieldsCommand
  }

export type SetStagedOrderShippingMethodOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    shippingMethodResId?: Maybe<ResourceIdentifier>
    externalTaxRate?: Maybe<ExternalTaxRateDraftOutput>
  }

export type SetStagedOrderShippingMethodTaxAmountOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    externalTaxAmount?: Maybe<ExternalTaxAmountDraftOutput>
  }

export type SetStagedOrderShippingMethodTaxRateOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    externalTaxRate?: Maybe<ExternalTaxRateDraftOutput>
  }

export type SetStagedOrderShippingRateInputOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    shippingRateInput?: Maybe<ShippingRateInputDraftOutput>
  }

export type SetStagedOrderStoreOutput = StagedOrderUpdateActionOutput & {
  type: Scalars['String']
  storeResId?: Maybe<ResourceIdentifier>
}

export type SetType = FieldType & {
  elementType: FieldType
  name: Scalars['String']
}

export enum ShipmentState {
  Delivered = 'Delivered',
  Delayed = 'Delayed',
  Backorder = 'Backorder',
  Partial = 'Partial',
  Pending = 'Pending',
  Ready = 'Ready',
  Shipped = 'Shipped',
}

export type ShippingInfo = {
  shippingMethodName: Scalars['String']
  price: Money
  shippingRate: ShippingRate
  taxRate?: Maybe<TaxRate>
  deliveries: Array<Delivery>
  discountedPrice?: Maybe<DiscountedLineItemPrice>
  taxedPrice?: Maybe<TaxedItemPrice>
  shippingMethodState: ShippingMethodState
  shippingMethod?: Maybe<ShippingMethod>
  shippingMethodRef?: Maybe<Reference>
  taxCategory?: Maybe<TaxCategory>
  taxCategoryRef?: Maybe<Reference>
}

export type ShippingMethod = Versioned &
  ReferenceExpandable & {
    name: Scalars['String']
    zoneRates: Array<ZoneRate>
    isDefault: Scalars['Boolean']
    predicate?: Maybe<Scalars['String']>
    key?: Maybe<Scalars['String']>
    taxCategoryRef?: Maybe<Reference>
    localizedDescriptionAllLocales?: Maybe<Array<LocalizedString>>
    localizedNameAllLocales?: Maybe<Array<LocalizedString>>
    localizedDescription?: Maybe<Scalars['String']>
    localizedName?: Maybe<Scalars['String']>
    taxCategory?: Maybe<TaxCategory>
    custom?: Maybe<CustomFieldsType>
    id: Scalars['String']
    version: Scalars['Long']
    createdAt: Scalars['DateTime']
    lastModifiedAt: Scalars['DateTime']
    createdBy?: Maybe<Initiator>
    lastModifiedBy?: Maybe<Initiator>
  }

export type ShippingMethodLocalizedDescriptionArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type ShippingMethodLocalizedNameArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export enum ShippingMethodState {
  /** Either there is no predicate defined for the ShippingMethod or the given predicate matches the cart */
  MatchesCart = 'MatchesCart',
  /** The ShippingMethod predicate does not match the cart. Ordering this cart will fail with error ShippingMethodDoesNotMatchCart */
  DoesNotMatchCart = 'DoesNotMatchCart',
}

/** Shipping Rate */
export type ShippingRate = {
  price: Money
  freeAbove?: Maybe<Money>
  isMatching?: Maybe<Scalars['Boolean']>
  tiers: Array<ShippingRatePriceTier>
}

export type ShippingRateCartClassificationPriceTier = ShippingRatePriceTier & {
  value: Scalars['String']
  price: Money
  isMatching?: Maybe<Scalars['Boolean']>
  type: Scalars['String']
}

export type ShippingRateCartScorePriceTier = ShippingRatePriceTier & {
  score: Scalars['Int']
  price?: Maybe<Money>
  priceFunction?: Maybe<PriceFunction>
  isMatching?: Maybe<Scalars['Boolean']>
  type: Scalars['String']
}

export type ShippingRateCartValuePriceTier = ShippingRatePriceTier & {
  minimumCentAmount: Scalars['Int']
  price: Money
  isMatching?: Maybe<Scalars['Boolean']>
  type: Scalars['String']
}

export type ShippingRateInput = {
  type: Scalars['String']
}

export type ShippingRateInputDraftOutput = {
  type: Scalars['String']
}

export type ShippingRatePriceTier = {
  type: Scalars['String']
}

export type ShippingTarget = CartDiscountTarget & {
  type: Scalars['String']
}

export type ShoppingList = Versioned &
  ReferenceExpandable & {
    key?: Maybe<Scalars['String']>
    name?: Maybe<Scalars['String']>
    nameAllLocales: Array<LocalizedString>
    description?: Maybe<Scalars['String']>
    descriptionAllLocales?: Maybe<Array<LocalizedString>>
    slug?: Maybe<Scalars['String']>
    slugAllLocales?: Maybe<Array<LocalizedString>>
    customerRef?: Maybe<Reference>
    customer?: Maybe<Customer>
    storeRef?: Maybe<KeyReference>
    store?: Maybe<Store>
    anonymousId?: Maybe<Scalars['String']>
    lineItems: Array<ShoppingListLineItem>
    textLineItems: Array<TextLineItem>
    custom?: Maybe<CustomFieldsType>
    deleteDaysAfterLastModification?: Maybe<Scalars['Int']>
    id: Scalars['String']
    version: Scalars['Long']
    createdAt: Scalars['DateTime']
    lastModifiedAt: Scalars['DateTime']
    createdBy?: Maybe<Initiator>
    lastModifiedBy?: Maybe<Initiator>
  }

export type ShoppingListNameArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type ShoppingListDescriptionArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type ShoppingListSlugArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type ShoppingListLineItem = {
  id: Scalars['String']
  productId: Scalars['String']
  variantId?: Maybe<Scalars['Int']>
  productTypeRef: Reference
  productType: ProductTypeDefinition
  quantity: Scalars['Int']
  addedAt: Scalars['DateTime']
  name?: Maybe<Scalars['String']>
  nameAllLocales: Array<LocalizedString>
  deactivatedAt?: Maybe<Scalars['DateTime']>
  custom?: Maybe<CustomFieldsType>
  productSlug?: Maybe<Scalars['String']>
  productSlugAllLocales?: Maybe<Array<LocalizedString>>
  variant?: Maybe<ProductVariant>
}

export type ShoppingListLineItemNameArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type ShoppingListLineItemProductSlugArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type SourceDesign = {
  made_of: Scalars['Int']
}

export type SpoonDollars = {
  available: Scalars['String']
  raw_available: Scalars['Float']
  currency?: Maybe<AlpCurrency>
  raw_applied?: Maybe<Scalars['Float']>
  raw_max_available_to_apply?: Maybe<Scalars['Float']>
  raw_applied_usd?: Maybe<Scalars['Float']>
  raw_max_available_to_apply_usd?: Maybe<Scalars['Float']>
}

/** Describes how this discount interacts with other discounts */
export enum StackingMode {
  /** Don’t apply any more matching discounts after this one. */
  StopAfterThisDiscount = 'StopAfterThisDiscount',
  /** Default. Continue applying other matching discounts after applying this one. */
  Stacking = 'Stacking',
}

export type StagedOrderUpdateActionOutput = {
  type: Scalars['String']
}

export type StagedQuote = Versioned & {
  key?: Maybe<Scalars['String']>
  stagedQuoteState: StagedQuoteState
  quoteRequestRef: Reference
  quoteRequest?: Maybe<QuoteRequest>
  quotationCartRef: Reference
  quotationCart?: Maybe<Cart>
  customerRef?: Maybe<Reference>
  customer?: Maybe<Customer>
  validTo?: Maybe<Scalars['DateTime']>
  custom?: Maybe<CustomFieldsType>
  stateRef?: Maybe<Reference>
  state?: Maybe<State>
  sellerComment?: Maybe<Scalars['String']>
  businessUnit?: Maybe<KeyReference>
  id: Scalars['String']
  version: Scalars['Long']
  createdAt: Scalars['DateTime']
  lastModifiedAt: Scalars['DateTime']
  createdBy?: Maybe<Initiator>
  lastModifiedBy?: Maybe<Initiator>
}

export enum StagedQuoteState {
  Closed = 'Closed',
  InProgress = 'InProgress',
  Sent = 'Sent',
}

/** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
export type StagedStandalonePrice = {
  value: BaseMoney
  discounted?: Maybe<DiscountedProductPriceValue>
}

/**
 * StandalonePrices are managed and queried through the StandalonePrices API
 * and associated to a ProductVariant through the sku field.
 */
export type StandalonePrice = Versioned & {
  id: Scalars['String']
  version: Scalars['Long']
  key?: Maybe<Scalars['String']>
  sku: Scalars['String']
  value: BaseMoney
  country?: Maybe<Country>
  customerGroupRef?: Maybe<Reference>
  channelRef?: Maybe<Reference>
  validFrom?: Maybe<Scalars['DateTime']>
  validUntil?: Maybe<Scalars['DateTime']>
  tiers?: Maybe<Array<ProductPriceTier>>
  discounted?: Maybe<DiscountedProductPriceValue>
  /** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
  staged?: Maybe<StagedStandalonePrice>
  custom?: Maybe<CustomFieldsType>
  expiresAt?: Maybe<Scalars['DateTime']>
  /** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
  active: Scalars['Boolean']
  createdAt: Scalars['DateTime']
  lastModifiedAt: Scalars['DateTime']
  createdBy?: Maybe<Initiator>
  lastModifiedBy?: Maybe<Initiator>
}

/** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
export type StandalonePriceActiveChanged = MessagePayload & {
  active: Scalars['Boolean']
  oldActive: Scalars['Boolean']
  type: Scalars['String']
}

export type StandalonePriceCreated = MessagePayload & {
  standalonePrice: StandalonePrice
  type: Scalars['String']
}

export type StandalonePriceDeleted = MessagePayload & {
  type: Scalars['String']
}

export type StandalonePriceDiscountSet = MessagePayload & {
  discounted?: Maybe<DiscountedProductPriceValue>
  type: Scalars['String']
}

/** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
export type StandalonePriceExpiresAtSet = MessagePayload & {
  expiresAt?: Maybe<Scalars['DateTime']>
  type: Scalars['String']
}

export type StandalonePriceExternalDiscountSet = MessagePayload & {
  discounted?: Maybe<DiscountedProductPriceValue>
  type: Scalars['String']
}

export type StandalonePriceKeySet = MessagePayload & {
  key?: Maybe<Scalars['String']>
  oldKey?: Maybe<Scalars['String']>
  type: Scalars['String']
}

/** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
export type StandalonePriceStagedChangesApplied = MessagePayload & {
  stagedChanges: StagedStandalonePrice
  type: Scalars['String']
}

export type StandalonePriceValueChanged = MessagePayload & {
  value: BaseMoney
  /** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
  staged: Scalars['Boolean']
  type: Scalars['String']
}

/** [State](https://docs.commercetools.com/api/projects/states) */
export type State = Versioned &
  ReferenceExpandable & {
    id: Scalars['String']
    version: Scalars['Long']
    key?: Maybe<Scalars['String']>
    type: StateType
    roles: Array<StateRole>
    name?: Maybe<Scalars['String']>
    nameAllLocales?: Maybe<Array<LocalizedString>>
    description?: Maybe<Scalars['String']>
    descriptionAllLocales?: Maybe<Array<LocalizedString>>
    builtIn: Scalars['Boolean']
    transitionsRef?: Maybe<Array<Reference>>
    transitions?: Maybe<Array<State>>
    initial: Scalars['Boolean']
    createdAt: Scalars['DateTime']
    lastModifiedAt: Scalars['DateTime']
    createdBy?: Maybe<Initiator>
    lastModifiedBy?: Maybe<Initiator>
  }

/** [State](https://docs.commercetools.com/api/projects/states) */
export type StateNameArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

/** [State](https://docs.commercetools.com/api/projects/states) */
export type StateDescriptionArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export enum StateRole {
  Return = 'Return',
  ReviewIncludedInStatistics = 'ReviewIncludedInStatistics',
}

export enum StateType {
  QuoteState = 'QuoteState',
  StagedQuoteState = 'StagedQuoteState',
  QuoteRequestState = 'QuoteRequestState',
  OrderState = 'OrderState',
  ProductState = 'ProductState',
  ReviewState = 'ReviewState',
  PaymentState = 'PaymentState',
  LineItemState = 'LineItemState',
}

/** Stores allow defining different contexts for a project. */
export type Store = Versioned &
  ReferenceExpandable & {
    id: Scalars['String']
    version: Scalars['Long']
    key: Scalars['String']
    name?: Maybe<Scalars['String']>
    nameAllLocales?: Maybe<Array<LocalizedString>>
    languages?: Maybe<Array<Scalars['Locale']>>
    /** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
    countries?: Maybe<Array<StoreCountry>>
    /** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
    productSelections: Array<ProductSelectionSetting>
    distributionChannelsRef: Array<Reference>
    distributionChannels: Array<Channel>
    supplyChannelsRef: Array<Reference>
    supplyChannels: Array<Channel>
    createdAt: Scalars['DateTime']
    lastModifiedAt: Scalars['DateTime']
    custom?: Maybe<CustomFieldsType>
    createdBy?: Maybe<Initiator>
    lastModifiedBy?: Maybe<Initiator>
  }

/** Stores allow defining different contexts for a project. */
export type StoreNameArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

/** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
export type StoreCountriesChanged = MessagePayload & {
  addedCountries?: Maybe<Array<StoreCountry>>
  removedCountries?: Maybe<Array<StoreCountry>>
  type: Scalars['String']
}

export type StoreCountry = {
  code: Country
}

export type StoreCreated = MessagePayload & {
  languages: Array<Scalars['Locale']>
  custom?: Maybe<CustomFieldsType>
  name?: Maybe<Scalars['String']>
  /** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
  productSelections: Array<ProductSelectionSetting>
  distributionChannels: Array<Channel>
  supplyChannels: Array<Channel>
  /** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
  countries?: Maybe<Array<StoreCountry>>
  distributionChannelsRef: Array<Reference>
  supplyChannelsRef: Array<Reference>
  productSelectionsRef: Array<Reference>
  nameAllLocales?: Maybe<Array<LocalizedString>>
  type: Scalars['String']
}

export type StoreCreatedNameArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type StoreDeleted = MessagePayload & {
  type: Scalars['String']
}

export type StoreDistributionChannelsChanged = MessagePayload & {
  addedDistributionChannels?: Maybe<Array<Channel>>
  removedDistributionChannels?: Maybe<Array<Channel>>
  addedDistributionChannelsRef?: Maybe<Array<Reference>>
  removedDistributionChannelsRef?: Maybe<Array<Reference>>
  type: Scalars['String']
}

export type StoreLanguagesChanged = MessagePayload & {
  addedLanguages?: Maybe<Array<Scalars['Locale']>>
  removedLanguages?: Maybe<Array<Scalars['Locale']>>
  type: Scalars['String']
}

export type StoreNameSet = MessagePayload & {
  name?: Maybe<Scalars['String']>
  nameAllLocales?: Maybe<Array<LocalizedString>>
  type: Scalars['String']
}

export type StoreNameSetNameArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

/** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
export type StoreProductSelectionsChanged = MessagePayload & {
  addedProductSelections?: Maybe<Array<ProductSelectionSetting>>
  removedProductSelections?: Maybe<Array<ProductSelectionSetting>>
  updatedProductSelections?: Maybe<Array<ProductSelectionSetting>>
  type: Scalars['String']
}

export type StoreSupplyChannelsChanged = MessagePayload & {
  addedSupplyChannels?: Maybe<Array<Channel>>
  removedSupplyChannels?: Maybe<Array<Channel>>
  addedSupplyChannelsRef?: Maybe<Array<Reference>>
  removedSupplyChannelsRef?: Maybe<Array<Reference>>
  type: Scalars['String']
}

export type StringType = FieldType & {
  name: Scalars['String']
}

export type SubRate = {
  name: Scalars['String']
  amount: Scalars['Float']
}

export type SuggestTokenizer = {
  type: Scalars['String']
}

/** Stores information about order synchronization activities (like export or import). */
export type SyncInfo = {
  channelRef: Reference
  channel?: Maybe<Channel>
  externalId?: Maybe<Scalars['String']>
  syncedAt: Scalars['DateTime']
}

export enum TaxCalculationMode {
  /**
   * This calculation mode calculates the taxes on the unit price before multiplying with the quantity.
   * E.g. `($1.08 * 1.19 = $1.2852 -> $1.29 rounded) * 3 = $3.87`
   */
  UnitPriceLevel = 'UnitPriceLevel',
  /**
   * Default. This calculation mode calculates the taxes after the unit price is multiplied with the quantity.
   * E.g. `($1.08 * 3 = $3.24) * 1.19 = $3.8556 -> $3.86 rounded`
   */
  LineItemLevel = 'LineItemLevel',
}

/** Tax Categories define how products are to be taxed in different countries. */
export type TaxCategory = Versioned &
  ReferenceExpandable & {
    name: Scalars['String']
    description?: Maybe<Scalars['String']>
    rates: Array<TaxRate>
    key?: Maybe<Scalars['String']>
    id: Scalars['String']
    version: Scalars['Long']
    createdAt: Scalars['DateTime']
    lastModifiedAt: Scalars['DateTime']
    createdBy?: Maybe<Initiator>
    lastModifiedBy?: Maybe<Initiator>
  }

export enum TaxMode {
  /** No taxes are added to the cart. */
  Disabled = 'Disabled',
  /**
   * The tax amounts and the tax rates as well as the tax portions are set externally per ExternalTaxAmountDraft.
   * A cart with this tax mode can only be ordered if the cart itself and all line items, all custom line items and
   * the shipping method have an external tax amount and rate set
   */
  ExternalAmount = 'ExternalAmount',
  /**
   * The tax rates are set externally per ExternalTaxRateDraft. A cart with this tax mode can only be ordered if all
   * line items, all custom line items and the shipping method have an external tax rate set. The totalNet and
   * totalGross as well as the taxPortions fields are calculated according to the taxRoundingMode.
   */
  External = 'External',
  /**
   * The tax rates are selected from the TaxCategories based on the cart shipping address.
   * The totalNet and totalGross as well as the taxPortions fields are calculated according to the
   * taxRoundingMode.
   */
  Platform = 'Platform',
}

/**
 * Represents the portions that sum up to the totalGross field of a TaxedPrice. The portions are calculated
 * from the TaxRates. If a tax rate has SubRates, they are used and can be identified by name. Tax portions
 * from line items that have the same rate and name will be accumulated to the same tax portion.
 */
export type TaxPortion = {
  rate: Scalars['Float']
  amount: Money
  name?: Maybe<Scalars['String']>
}

export type TaxRate = {
  name: Scalars['String']
  amount: Scalars['Float']
  includedInPrice: Scalars['Boolean']
  country: Country
  state?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  subRates: Array<SubRate>
}

export type TaxedItemPrice = {
  totalNet: Money
  totalGross: Money
  totalTax?: Maybe<Money>
}

export type TaxedPrice = {
  totalNet: Money
  totalGross: Money
  taxPortions: Array<TaxPortion>
  totalTax?: Maybe<Money>
}

export type TextAttributeDefinitionType = AttributeDefinitionType & {
  name: Scalars['String']
}

/** UI hint telling what kind of edit control should be displayed for a text attribute. */
export enum TextInputHint {
  SingleLine = 'SingleLine',
  MultiLine = 'MultiLine',
}

export type TextLineItem = {
  id: Scalars['String']
  name?: Maybe<Scalars['String']>
  nameAllLocales: Array<LocalizedString>
  description?: Maybe<Scalars['String']>
  descriptionAllLocales?: Maybe<Array<LocalizedString>>
  quantity: Scalars['Int']
  custom?: Maybe<CustomFieldsType>
  addedAt: Scalars['DateTime']
}

export type TextLineItemNameArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type TextLineItemDescriptionArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

export type TimeAttributeDefinitionType = AttributeDefinitionType & {
  name: Scalars['String']
}

export type TimeType = FieldType & {
  name: Scalars['String']
}

export type TrackingData = {
  trackingId?: Maybe<Scalars['String']>
  carrier?: Maybe<Scalars['String']>
  provider?: Maybe<Scalars['String']>
  providerTransaction?: Maybe<Scalars['String']>
  isReturn: Scalars['Boolean']
}

export type Transaction = {
  id: Scalars['String']
  timestamp?: Maybe<Scalars['DateTime']>
  type?: Maybe<TransactionType>
  amount: Money
  interactionId?: Maybe<Scalars['String']>
  state: TransactionState
  custom?: Maybe<CustomFieldsType>
}

export enum TransactionState {
  Failure = 'Failure',
  Success = 'Success',
  Pending = 'Pending',
  Initial = 'Initial',
}

export enum TransactionType {
  Chargeback = 'Chargeback',
  Refund = 'Refund',
  Charge = 'Charge',
  CancelAuthorization = 'CancelAuthorization',
  Authorization = 'Authorization',
}

export type TransitionStagedOrderCustomLineItemStateOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    customLineItemId: Scalars['String']
    quantity: Scalars['Long']
    fromStateResId: ResourceIdentifier
    toStateResId: ResourceIdentifier
    actualTransitionDate?: Maybe<Scalars['DateTime']>
  }

export type TransitionStagedOrderLineItemStateOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    lineItemId: Scalars['String']
    quantity: Scalars['Long']
    fromStateResId: ResourceIdentifier
    toStateResId: ResourceIdentifier
    actualTransitionDate?: Maybe<Scalars['DateTime']>
  }

export type TransitionStagedOrderStateOutput = StagedOrderUpdateActionOutput & {
  type: Scalars['String']
  stateResId: ResourceIdentifier
  force: Scalars['Boolean']
}

export type Trigger = {
  resourceTypeId: Scalars['String']
  actions: Array<ActionType>
  condition?: Maybe<Scalars['String']>
}

export type TurnTimes = {
  STANDARD?: Maybe<Turnarounds>
  RUSH?: Maybe<Turnarounds>
  GUARANTEED?: Maybe<Turnarounds>
}

export type Turnaround = {
  min_days: Scalars['Int']
  max_days: Scalars['Int']
}

export type Turnarounds = {
  STANDARD?: Maybe<Turnaround>
  min_days?: Maybe<Scalars['Int']>
  max_days?: Maybe<Scalars['Int']>
  minShipsByDate: Scalars['String']
  minArrivesByDate: Scalars['String']
  shipsByDate: Scalars['String']
  arrivesByDate: Scalars['String']
}

/** Types allow you to define additional project-specific fields on resources and data types, so-called Custom Fields. */
export type TypeDefinition = Versioned &
  ReferenceExpandable & {
    key: Scalars['String']
    name?: Maybe<Scalars['String']>
    description?: Maybe<Scalars['String']>
    nameAllLocales: Array<LocalizedString>
    descriptionAllLocales?: Maybe<Array<LocalizedString>>
    resourceTypeIds: Array<Scalars['String']>
    fieldDefinitions: Array<FieldDefinition>
    id: Scalars['String']
    version: Scalars['Long']
    createdAt: Scalars['DateTime']
    lastModifiedAt: Scalars['DateTime']
    createdBy?: Maybe<Initiator>
    lastModifiedBy?: Maybe<Initiator>
  }

/** Types allow you to define additional project-specific fields on resources and data types, so-called Custom Fields. */
export type TypeDefinitionNameArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

/** Types allow you to define additional project-specific fields on resources and data types, so-called Custom Fields. */
export type TypeDefinitionDescriptionArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

/** Types allow you to define additional project-specific fields on resources and data types, so-called Custom Fields. */
export type TypeDefinitionFieldDefinitionsArgs = {
  includeNames?: InputMaybe<Array<Scalars['String']>>
  excludeNames?: InputMaybe<Array<Scalars['String']>>
}

export type UpdateStagedOrderItemShippingAddressOutput =
  StagedOrderUpdateActionOutput & {
    type: Scalars['String']
    address: AddressDraft
  }

export type UpdateStagedOrderSyncInfoOutput = StagedOrderUpdateActionOutput & {
  type: Scalars['String']
  channelResId: ChannelReferenceIdentifier
  syncedAt?: Maybe<Scalars['DateTime']>
  externalId?: Maybe<Scalars['String']>
}

export type User = {
  admin?: Maybe<Scalars['Boolean']>
  business_tools?: Maybe<Scalars['Boolean']>
  ctUser?: Maybe<CtUser>
  design_owner?: Maybe<Scalars['Boolean']>
  email?: Maybe<Scalars['String']>
  favorites?: Maybe<Favorites>
  guest?: Maybe<Scalars['Boolean']>
  guest_cart_id?: Maybe<Scalars['String']>
  hasLegacyItems?: Maybe<Scalars['Boolean']>
  hasSavedLegacyItems?: Maybe<Scalars['Boolean']>
  id: Scalars['Int']
  newsletter_opt_in?: Maybe<Scalars['Boolean']>
  order_id_last_paid?: Maybe<Scalars['Int']>
  order_id_pending?: Maybe<Scalars['Int']>
  preferences?: Maybe<Preferences>
  pro?: Maybe<Scalars['Boolean']>
  plus: Plus
  screen_name?: Maybe<Scalars['String']>
  spoondollars?: Maybe<SpoonDollars>
  trade?: Maybe<Scalars['Boolean']>
  stayLegacyUser?: Maybe<Scalars['Boolean']>
  research?: Maybe<Scalars['Boolean']>
}

export type UserProvidedIdentifiers = {
  key?: Maybe<Scalars['String']>
  orderNumber?: Maybe<Scalars['String']>
  customerNumber?: Maybe<Scalars['String']>
  externalId?: Maybe<Scalars['String']>
  sku?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  slugAllLocales?: Maybe<Array<LocalizedString>>
}

export type UserProvidedIdentifiersSlugArgs = {
  locale?: InputMaybe<Scalars['Locale']>
  acceptLanguage?: InputMaybe<Array<Scalars['Locale']>>
}

/** Versioned object have an ID and version and modification. Every update of this object changes it's version. */
export type Versioned = {
  id: Scalars['String']
  version: Scalars['Long']
  createdAt: Scalars['DateTime']
  lastModifiedAt: Scalars['DateTime']
  createdBy?: Maybe<Initiator>
  lastModifiedBy?: Maybe<Initiator>
}

export type WhitespaceSuggestTokenizer = SuggestTokenizer & {
  type: Scalars['String']
}

/** Zones allow defining ShippingRates for specific Locations. */
export type Zone = Versioned &
  ReferenceExpandable & {
    name: Scalars['String']
    key?: Maybe<Scalars['String']>
    description?: Maybe<Scalars['String']>
    locations: Array<Location>
    id: Scalars['String']
    version: Scalars['Long']
    createdAt: Scalars['DateTime']
    lastModifiedAt: Scalars['DateTime']
    createdBy?: Maybe<Initiator>
    lastModifiedBy?: Maybe<Initiator>
  }

export type ZoneRate = {
  shippingRates: Array<ShippingRate>
  zoneRef?: Maybe<Reference>
  zone?: Maybe<Zone>
}

export type CartcountQueryVariables = Exact<{ [key: string]: never }>

export type CartcountQuery = { cartCount?: { cartCount: number } | null }

export type DesignSalabilityRequirementsQueryVariables = Exact<{
  designIDs: Array<Scalars['Int']> | Scalars['Int']
}>

export type DesignSalabilityRequirementsQuery = {
  designSalabilityRequirements?: Array<{
    artistID: number
    designID: number
    isCentered: boolean
    isDeleted: boolean
    isEnabledForHomeGoods: boolean
    isExclusiveToFTTWH: boolean
    isForSale: boolean
    isForSaleAsFabric: boolean
    isForSaleAsWallpaper: boolean
    isPrivate: boolean
  }> | null
}

export type PrivateBuyersQueryQueryVariables = Exact<{
  userID: Scalars['Int']
  designID: Scalars['Int']
}>

export type PrivateBuyersQueryQuery = {
  privateBuyer?: { design_id: number; id: number; user_id: number } | null
}

export type SourceDesignsQueryQueryVariables = Exact<{
  designID: Scalars['Int']
}>

export type SourceDesignsQueryQuery = {
  sourceDesigns?: Array<{ made_of: number }> | null
}

export type TurnaroundsQueryVariables = Exact<{
  country?: InputMaybe<Scalars['String']>
}>

export type TurnaroundsQuery = {
  myTurnarounds?: {
    summary: {
      GUARANTEED?: {
        max_days?: number | null
        minShipsByDate: string
        minArrivesByDate: string
        shipsByDate: string
        arrivesByDate: string
      } | null
      RUSH?: {
        max_days?: number | null
        minShipsByDate: string
        minArrivesByDate: string
        shipsByDate: string
        arrivesByDate: string
      } | null
      STANDARD?: {
        max_days?: number | null
        minShipsByDate: string
        minArrivesByDate: string
        shipsByDate: string
        arrivesByDate: string
      } | null
    }
  } | null
}

export type UpdatePreferencesMutationVariables = Exact<{
  preferences: PreferencesInput
}>

export type UpdatePreferencesMutation = {
  updatePreferences?: {
    preferences?: {
      adult_content?: AdultContent | null
      country?: Country | null
      currency?: AlpCurrency | null
      locale?: string | null
      measurement_system?: MeasurementSystem | null
    } | null
  } | null
}

export type CtUserFragment = { id: string } & {
  ' $fragmentName'?: 'CtUserFragment'
}

export type FavoritesFragment = { design_ids?: Array<number | null> | null } & {
  ' $fragmentName'?: 'FavoritesFragment'
}

export type PreferencesFragment = {
  currency?: AlpCurrency | null
  measurement_system?: MeasurementSystem | null
  country?: Country | null
  locale?: string | null
  adult_content?: AdultContent | null
} & { ' $fragmentName'?: 'PreferencesFragment' }

export type SpoondollarsFragment = {
  available: string
  raw_available: number
  currency?: AlpCurrency | null
  raw_applied?: number | null
  raw_max_available_to_apply?: number | null
  raw_applied_usd?: number | null
  raw_max_available_to_apply_usd?: number | null
} & { ' $fragmentName'?: 'SpoondollarsFragment' }

export type UserQueryVariables = Exact<{ [key: string]: never }>

export type UserQuery = {
  user?: {
    business_tools?: boolean | null
    design_owner?: boolean | null
    email?: string | null
    guest?: boolean | null
    guest_cart_id?: string | null
    id: number
    newsletter_opt_in?: boolean | null
    order_id_last_paid?: number | null
    order_id_pending?: number | null
    pro?: boolean | null
    screen_name?: string | null
    stayLegacyUser?: boolean | null
    research?: boolean | null
    ctUser?: { ' $fragmentRefs'?: { CtUserFragment: CtUserFragment } } | null
    favorites?: {
      ' $fragmentRefs'?: { FavoritesFragment: FavoritesFragment }
    } | null
    preferences?: {
      ' $fragmentRefs'?: { PreferencesFragment: PreferencesFragment }
    } | null
    plus: {
      expirationDate?: any | null
      isActive: boolean
      isRenewable?: boolean | null
    }
    spoondollars?: {
      ' $fragmentRefs'?: { SpoondollarsFragment: SpoondollarsFragment }
    } | null
  } | null
}

export const CtUserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ctUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CTUser' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
      },
    },
  ],
} as unknown as DocumentNode<CtUserFragment, unknown>
export const FavoritesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'favorites' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Favorites' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'design_ids' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FavoritesFragment, unknown>
export const PreferencesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'preferences' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Preferences' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'measurement_system' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'locale' } },
          { kind: 'Field', name: { kind: 'Name', value: 'adult_content' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PreferencesFragment, unknown>
export const SpoondollarsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'spoondollars' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SpoonDollars' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'available' } },
          { kind: 'Field', name: { kind: 'Name', value: 'raw_available' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'raw_applied' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'raw_max_available_to_apply' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'raw_applied_usd' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'raw_max_available_to_apply_usd' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SpoondollarsFragment, unknown>
export const CartcountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Cartcount' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cartCount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cartCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CartcountQuery, CartcountQueryVariables>
export const DesignSalabilityRequirementsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DesignSalabilityRequirements' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'designIDs' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'Int' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'designSalabilityRequirements' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'designIDs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'designIDs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'artistID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'designID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCentered' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDeleted' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isEnabledForHomeGoods' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isExclusiveToFTTWH' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isForSale' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isForSaleAsFabric' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isForSaleAsWallpaper' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DesignSalabilityRequirementsQuery,
  DesignSalabilityRequirementsQueryVariables
>
export const PrivateBuyersQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PrivateBuyersQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userID' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'designID' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'privateBuyer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userID' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userID' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'designID' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'designID' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'design_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PrivateBuyersQueryQuery,
  PrivateBuyersQueryQueryVariables
>
export const SourceDesignsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SourceDesignsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'designID' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sourceDesigns' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'designID' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'designID' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'made_of' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SourceDesignsQueryQuery,
  SourceDesignsQueryQueryVariables
>
export const TurnaroundsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Turnarounds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'country' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myTurnarounds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'country' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'country' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'GUARANTEED' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'max_days' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'minShipsByDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'minArrivesByDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shipsByDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'arrivesByDate' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RUSH' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'max_days' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'minShipsByDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'minArrivesByDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shipsByDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'arrivesByDate' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'STANDARD' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'max_days' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'minShipsByDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'minArrivesByDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shipsByDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'arrivesByDate' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TurnaroundsQuery, TurnaroundsQueryVariables>
export const UpdatePreferencesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePreferences' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'preferences' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PreferencesInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePreferences' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'preferences' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'preferences' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'preferences' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'adult_content' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'locale' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'measurement_system' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePreferencesMutation,
  UpdatePreferencesMutationVariables
>
export const UserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'User' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'business_tools' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ctUser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ctUser' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'design_owner' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'favorites' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'favorites' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'guest' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'guest_cart_id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'newsletter_opt_in' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'order_id_last_paid' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'order_id_pending' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'preferences' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'preferences' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'pro' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'plus' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'expirationDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isActive' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isRenewable' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'screen_name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stayLegacyUser' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'research' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'spoondollars' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'spoondollars' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ctUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CTUser' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'favorites' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Favorites' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'design_ids' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'preferences' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Preferences' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'measurement_system' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'locale' } },
          { kind: 'Field', name: { kind: 'Name', value: 'adult_content' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'spoondollars' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SpoonDollars' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'available' } },
          { kind: 'Field', name: { kind: 'Name', value: 'raw_available' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'raw_applied' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'raw_max_available_to_apply' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'raw_applied_usd' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'raw_max_available_to_apply_usd' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserQuery, UserQueryVariables>
